
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, Search, FileText, FileMedicalFill, Coin, Tree, Globe2, GeoAlt,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, HeartFill, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { render } from '@testing-library/react';
import
 {supremeCourtSearch, whiteHouseSearch, billFeedModal, 
  createHouseFloorToday, createSenateFloorToday, createTrendingBills,
  createNationalAeronauticsAndSpaceAdministration, 
  createEnvironmentalProtectionAgency, createDepartmentOfHomelandSecurity,
  createDepartmentOfVeteransAffairs, createDepartmentOfEducation,
  createDepartmentOfHousingAndUrbanDevelopment, 
  createDepartmentOfHealthAndHumanServices, createDepartmentOfLabor
, createDepartmentOfCommerce, createDepartmentOfAgriculture,
createDepartmentOfTheInterior, createDepartmentOfJustice,
createDepartmentOfTransportation, createDepartmentOfDefense,
createDepartmentOfTreasury, createDepartmentOfEnergy,
createDepartmentOfState, createBillsEnrolledMobile,
createBillsEnrolled, createNewLaws, createGAOReports,
createExecutiveOrders, createPresidentialProclamations, createCongressionalHearings,
createCongressionalBudgetOfficeMobile, createCongressionalBudgetOffice,
createHouseCommitteeOversightAndReform,
createHouseCommitteeOnNaturalResources,
createHouseCommitteeOnTheJudiciary,
createHouseCommitteeOnHouseAdministration,
createHouseCommitteeOnHomelandSecurity,
createHouseCommitteeOnForeignAffairs,
createHouseCommitteeOnFinancialServices,
createHouseCommitteeOnEthics,
createHouseCommitteeOnEnergyAndCommerce,
createHouseCommitteeOnEducationAndLabor,
createHouseCommitteeOnTheBudget,
createHouseCommitteeOnArmedServices,
createHouseCommitteeOnAppropriations,
createHouseCommitteeOnAgriculture,
createReports,
createBillFeedTwoMobile,
createBillFeedTwo,
createBillFeed, createWhiteHouseFeedMobile
, createWhiteHouseFeed, createWhiteHouseDocuments, 
createBillsPresentedFeed, createCourtAppealsMobile,
createCourtAppeals, createUSCourtNewsFeedMobile,
createUSCourtNewsFeed, saveToCollection,
createFullBillFeed, ListItem, Speak, feedText, variableTest, myMethod, myMethod2, createEconomicIndicators, 
createSecuritiesAndExchangeCommissionPressReleases, createNationWeatherServices, createCDCNewsroom,
createSecuritiesAndExchangeCommissionSpeechesAndStatements, createNationalScienceFoundation, createHouseCommitteeOnIntelligence,
 createUNTopStories, createCDCOutbreaks, createCDCTravelNotices, createFoodRecalls, createFoodSafety, createAlabamaBills, createAlaskaBills} from '../Functions'
import CollectionPage from '../CollectionPage';
import NavbarSearchButton from '../NavbarSearchButton';
import NavbarSearchButtonCanada from '../NavbarSearchButtonCanada';




var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 60000;

//https://evening-plains-21303.herokuapp.com/







  










export const CanadaDesktopNavbar = () =>   {
  render()
  
 
  {

  
  const [show, setShow] = useState(true);
  
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [fullscreen, setFullScreen] = useState(true);

  
  const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)

  const [reportModalIsOpen, setReportModalIsOpen] = useState(false)


  const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
  const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)


  const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
  const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
  const [courtOpinionsModalIsOpen, setCourtOpinionsModalIsOpen] = useState(false)
  const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)

  const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)

  const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
  const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
  const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
  const [secSAndSModalIsOpen, setSECSAndSModalIsOpen] = useState(false)
  const [economicIndicatorsModalIsOpen, setEconomicIndicatorsModalIsOpen] = useState(false)
  const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
  const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
  const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
  const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
  const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
  const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
  const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
  const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
  const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
  const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
  const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
  const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
  const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
  const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
  const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
  const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
  const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
  const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
  const [trendingBillsOptionOne, setTrendingBillsOptionOneModalIsOpen ] = useState(false)
  const [trendingBillsOptionTwo, setTrendingBillsOptionTwoModalIsOpen ] = useState(false)
  const [trendingBillsOptionThree, setTrendingBillsOptionThreeModalIsOpen ] = useState(false)
  const [trendingBillsOptionFour, setTrendingBillsOptionFourModalIsOpen ] = useState(false)
  const [trendingBillsOptionFive, setTrendingBillsOptionFiveModalIsOpen ] = useState(false)
  const [trendingBillsOptionSix, setTrendingBillsOptionSixModalIsOpen ] = useState(false)
  const [trendingBillsOptionSeven, setTrendingBillsOptionSevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEight, setTrendingBillsOptionEightModalIsOpen ] = useState(false)
  const [trendingBillsOptionNine, setTrendingBillsOptionNineModalIsOpen ] = useState(false)
  const [trendingBillsOptionTen, setTrendingBillsOptionTenModalIsOpen ] = useState(false)
  const [trendingBillsOptionEleven, setTrendingBillsOptionElevenModalIsOpen ] = useState(false)
  const [trendingBillsOptionTweleve, setTrendingBillsOptionTweleveModalIsOpen ] = useState(false)
  const [trendingBillsOptionThirteen, setTrendingBillsOptionThirteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFourteen, setTrendingBillsOptionFourteenModalIsOpen ] = useState(false)
  const [trendingBillsOptionFifteen, setTrendingBillsOptionFifteenModalIsOpen ] = useState(false)
  const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
    const [houseComitteeOnAgricultureModalIsOpen, setHouseComitteeOnAgricultureModalIsOpen] = useState(false)
    const [houseCommitteeOnAppropriationsModalIsOpen, setHouseCommitteeOnAppropriationsModalIsOpen] = useState(false)
    const [houseCommitteeOnArmedServicesModalIsOpen, setHouseCommitteeOnOnArmedServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnTheBudgetModalIsOpen, setHouseCommitteeOnTheBudgetModalIsOpen] = useState(false)
    const [houseCommitteeOnEnergyAndCommerceModalIsOpen, setHouseCommitteeOnEnergyAndCommerceModalIsOpen] = useState(false)
    const [houseCommitteeOnEducationAndLaborModalIsOpen, setHouseCommitteeOnEducationAndLaborModalIsOpen] = useState(false)
    const [houseCommitteeOnEthicsModalIsOpen, setHouseCommitteeOnEthicsModalIsOpen] = useState(false)
    const [houseCommitteeOnFinancialServicesModalIsOpen, setHouseCommitteeOnFinancialServicesModalIsOpen] = useState(false)
    const [houseCommitteeOnForeignAffairsModalIsOpen, setHouseCommitteeOnForeignAffairsModalIsOpen] = useState(false)
    const [houseCommitteeOnHomelandSecurityModalIsOpen, setHouseCommitteeOnHomelandSecurityModalIsOpen] = useState(false)
    const [houseCommitteeOnHouseAdministrationModalIsOpen, setHouseCommitteeOnHouseAdministrationModalIsOpen] = useState(false)
    const [houseCommitteeOnTheJudiciaryModalIsOpen, setHouseCommitteeOnTheJudiciaryModalIsOpen] = useState(false)
    const [houseCommitteeOnNaturalResourcesModalIsOpen, setHouseCommitteeOnNaturalResourcesModalIsOpen] = useState(false)
    const [houseCommitteeOnOversightAndReformModalIsOpen, setHouseCommitteeOnOversightAndReformModalIsOpen] = useState(false)
    const [congressionalBudgetOfficeModalIsOpen, setCongressionalBudgetOfficeModalIsOpen] = useState(false)
    const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
    const [cdcNewsroomModalIsOpen, setCDCNewsroomModalIsOpen] = useState(false)
    const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
    const [unTopStoriesModalIsOpen, setUNTopStoriesModalIsOpen] = useState(false)
    const [houseCommitteeOnIntelligenceModalIsOpen, setHouseCommitteeOnIntelligenceModalIsOpen] = useState(false)
    const [travelNoticesModalIsOpen, setTravelNoticesModalIsOpen] = useState(false)
  
    const [foodRecallsModalIsOpen, setFoodRecallsModalIsOpen] = useState(false)
    const [foodSafetyModalIsOpen, setFoodSafetyModalIsOpen] = useState(false)

    const [outbreaksModalIsOpen, setOutbreaksModalIsOpen] = useState(false)
    const [congressionalHearingsModalIsOpen, setCongressionalHearingsModalIsOpen] = useState(false)

    const [houseFloorModalIsOpen, setHouseFloorModalIsOpen] = useState(false)



  const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
  const [secPressModalIsOpen, setSECPressModalIsOpen] = useState(false)


  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)

  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)


 

   








  
  
  

{
  

  return (
    
    <>
    
      <div className="App">
   
    




      <BrowserView>
      <div id='homePage'>
        <div>
        <Navbar id='navbar' >
        
<Col>
          <Row id='realNavbarTop'>
                <Nav>
             
    

                
           <h3 id='branchesHeadingUS'><b id='sloganHeading'> </b></h3> 
                 <Nav.Link id='navbarAboutDesktop' ><Button className='clickable' id='navbarAboutButtonDesktop' onClick={(e) => {
                      e.preventDefault();
                      window.open('/', "_self")
                    }} > <Image id='govGlanceLogo' width='22px' src='/favicon.ico'></Image> Home
                        </Button></Nav.Link>
       
                <Nav.Link id='navbarAboutDesktop'><Button className='clickable' id='navbarAboutButtonDesktop' onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}}> <InfoCircle/> About</Button></Nav.Link>
                <Nav.Link id='navbarAboutDesktop'><Button className='clickable' id='navbarAboutButtonDesktop' onClick={(e) => {
                        e.preventDefault();
                        window.open('/collection', "_self") }}><Folder></Folder> Collection</Button></Nav.Link>
              <Nav.Link id='navbarAboutDesktop' >
                          <Dropdown drop="down-centered">

                            <Dropdown.Toggle id='navbarAboutButtonDesktop'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
</svg> Apps
                            </Dropdown.Toggle>
<Dropdown.Menu id="docsDropdown" drop="down-centered">
                              <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('https://apps.apple.com/us/app/gov-glance-news/id6448072618', "_blank")
                                }}
                              >
                                <svg id='appIconsDesktop' xmlns="http://www.w3.org/2000/svg" class="ionicon" height={24} fill='white' viewBox="0 0 512 512"><path d="M256 32C132.26 32 32 132.26 32 256s100.26 224 224 224 224-100.26 224-224S379.74 32 256 32zm-85 321.89a15.48 15.48 0 01-13.46 7.65 14.91 14.91 0 01-7.86-2.16 15.48 15.48 0 01-5.6-21.21l15.29-25.42a8.73 8.73 0 017.54-4.3h2.26c11.09 0 18.85 6.67 21.11 13.13zm129.45-50l-100.13.11h-66.55a15.46 15.46 0 01-15.51-16.15c.32-8.4 7.65-14.76 16-14.76h48.24l57.19-97.35-18.52-31.55C217 137 218.85 127.52 226 123a15.57 15.57 0 0121.87 5.17l9.9 16.91h.11l9.91-16.91A15.58 15.58 0 01289.6 123c7.11 4.52 8.94 14 4.74 21.22l-18.52 31.55-18 30.69-39.09 66.66v.11h57.61c7.22 0 16.27 3.88 19.93 10.12l.32.65c3.23 5.49 5.06 9.26 5.06 14.75a13.82 13.82 0 01-1.17 5.17zm77.75.11h-27.11v.11l19.82 33.71a15.8 15.8 0 01-5.17 21.53 15.53 15.53 0 01-8.08 2.27A15.71 15.71 0 01344.2 354l-29.29-49.86-18.2-31L273.23 233a38.35 38.35 0 01-.65-38c4.64-8.19 8.19-10.34 8.19-10.34L333 273h44.91c8.4 0 15.61 6.46 16 14.75A15.65 15.65 0 01378.23 304z" /></svg>
                      iOS
                              </Dropdown.Item>
                              <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('https://play.google.com/store/apps/details?id=com.govglance1&hl=en_US&gl=US', "_blank")
                                }}
                              >
                                <svg id='appIconsDesktop' xmlns="http://www.w3.org/2000/svg" class="ionicon" fill='white' height={24} viewBox="0 0 512 512"><path d="M48 59.49v393a4.33 4.33 0 007.37 3.07L260 256 55.37 56.42A4.33 4.33 0 0048 59.49zM345.8 174L89.22 32.64l-.16-.09c-4.42-2.4-8.62 3.58-5 7.06l201.13 192.32zM84.08 472.39c-3.64 3.48.56 9.46 5 7.06l.16-.09L345.8 338l-60.61-57.95zM449.38 231l-71.65-39.46L310.36 256l67.37 64.43L449.38 281c19.49-10.77 19.49-39.23 0-50z" />
                      </svg> Android
                              </Dropdown.Item>

                         
                            </Dropdown.Menu>
                            </Dropdown>
                        </Nav.Link>
               
               {/*<div id='unitedStateFlagPadding'><Image id='unitedStatedFlag'  src='govglanceUnitedStates.png'></Image></div>*/}
     

            

{/*
<Nav.Link id='navbarAboutDesktop'><Button className='clickable' id='navbarAboutButtonDesktop' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://open.spotify.com/show/25QBLBrHDcJ5odANeTBlUw', "_blank")
                      }}>    <svg id='spotifyIcon' xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-spotify" viewBox="0 0 16 16">
                      <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.669 11.538a.498.498 0 0 1-.686.165c-1.879-1.147-4.243-1.407-7.028-.77a.499.499 0 0 1-.222-.973c3.048-.696 5.662-.397 7.77.892a.5.5 0 0 1 .166.686zm.979-2.178a.624.624 0 0 1-.858.205c-2.15-1.321-5.428-1.704-7.972-.932a.625.625 0 0 1-.362-1.194c2.905-.881 6.517-.454 8.986 1.063a.624.624 0 0 1 .206.858zm.084-2.268C10.154 5.56 5.9 5.419 3.438 6.166a.748.748 0 1 1-.434-1.432c2.825-.857 7.523-.692 10.492 1.07a.747.747 0 1 1-.764 1.288z"/>
                    </svg>
U.S. Weekly Update</Button></Nav.Link>
                    */}
{/*
<Nav.Link id='navbarAboutDesktop'><Button className='clickable' id='navbarAboutButtonDesktop' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://twitter.com/govglance', "_blank")
                      }}>    <svg id='spotifyIcon' xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                    </svg>
Twitter </Button></Nav.Link>
                    */}

<Nav.Link id='navbarAboutDesktop'><Button className='clickable' id='navbarAboutButtonDesktop' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button></Nav.Link>
<NavbarSearchButtonCanada/>



     
                
               
           
                
                
                </Nav>

              
           
              </Row>
              <Row id='testRow'>

              <Navbar id='bottomNavbar' >
          


          <Nav variant='pills' className="mr-auto">

    {/* onClick={(e) => {
                  e.preventDefault();
                  window.open('/us', "_self")}} */}
                  <Nav.Link id ='navbarAboutDesktop' >
                  <Dropdown drop="down-centered">

<Dropdown.Toggle id='navbarAboutButtonDesktop'>
  🇨🇦
</Dropdown.Toggle>


<Dropdown.Menu id="docsDropdown" drop="down-centered">
<Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('/', "_self");
                                }}
                              >
                                🌐 All Countries
                              </Dropdown.Item>
<Dropdown.Item
id="docsDropdownItem"
onClick={(e) => {
  e.preventDefault();
  window.open('/canada', "_self");
}}
>
🇨🇦 Canada
</Dropdown.Item>
<Dropdown.Item
id="docsDropdownItem"
onClick={(e) => {
  e.preventDefault();
  window.open('/mexico', "_self");
}}
>
🇲🇽 Mexico
</Dropdown.Item>
<Dropdown.Item
id="docsDropdownItem"
onClick={(e) => {
  e.preventDefault();
  window.open('/uk', "_self");
}}
>
🇬🇧 United Kingdom
</Dropdown.Item>
<Dropdown.Item
id="docsDropdownItem"
onClick={(e) => {
  e.preventDefault();
  window.open('/us', "_self");
}}
>
🇺🇸 United States
</Dropdown.Item>
</Dropdown.Menu>
</Dropdown>


                  </Nav.Link>

  
<Nav.Link id ='navbarAboutDesktop' >
<Dropdown >
<Dropdown.Toggle id='navbarAboutButtonDesktop' >
<Files/>
</Dropdown.Toggle>

<Dropdown.Menu id='docsDropdown'>
<Dropdown.Item id='docsDropdownItem'  onClick={() => {
             
             window.open("https://laws-lois.justice.gc.ca/eng/const/", "_blank")}} >Constitution</Dropdown.Item>


<Dropdown.Item id='docsDropdownItem' onClick={() => {
             
             window.open("https://laws-lois.justice.gc.ca/eng/Const/page-12.html", "_blank")}} >Charter of Rights and Freedoms</Dropdown.Item>




</Dropdown.Menu>



</Dropdown>
</Nav.Link>

<div id='branchPageButtonPadding'> <h3 id='branchesHeading'><b id='branchHeadingBold'>Branches</b></h3> </div>

<div id='branchPageButtonPadding'>
  <Button className='clickable' id='navbarAboutButtonDesktop' href='/canada/legislative' >Legislative</Button></div>
<div id='branchPageButtonPadding'> <Button className='clickable' id='navbarAboutButtonDesktop' href='/canada/executive' >Executive</Button></div>
<div id='branchPageButtonPadding'><Button className='clickable' id='navbarAboutButtonDesktop' href='/canada/judicial' >Judicial</Button></div>



<div id='branchPageButtonPadding'> <h3 id='branchesHeading'><b id='branchHeadingBold'>Topics</b></h3> </div>
<div id='branchPageButtonPadding'>
  <Button className='clickable' id='navbarAboutButtonDesktop' href='/canada/defense' >Defense</Button></div>
<div id='branchPageButtonPadding'>
  <Button className='clickable' id='navbarAboutButtonDesktop' href='/canada/economy'>Economy</Button></div>

<div id='branchPageButtonPadding'>
  <Button className='clickable' id='navbarAboutButtonDesktop' href='/canada/environment' >Environment</Button></div>
  <div id='branchPageButtonPadding'>
  <Button className='clickable' id='navbarAboutButtonDesktop' href='/canada/foreignaffairs' >Foreign Affairs</Button></div>

  <div id='branchPageButtonPadding'>
  <Button className='clickable' id='navbarAboutButtonDesktop' href='/canada/science&tech' >Science & Tech</Button></div>

  <div id='branchPageButtonPadding'>
<Button className='clickable' id='navbarAboutButtonDesktop'  onClick={() => setStatesModalIsOpen(true)}  >Provinces</Button></div>

<Modal id='fullBillModal' show={statesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setStatesModalIsOpen(false)}>
<Modal.Header id='billModalHeader' closeButton><h3 id='branchHeading' > <GeoAlt></GeoAlt> <b id='branchHeadingBold'> Select a Province</b></h3>
</Modal.Header>

<div id='homebg'>  
<Container>
        <Col>
        <Row id='statesRow'>
    <Button className='clickable' size='sm' id='statesModalButtons'  onClick={() =>  {
         
          window.open('/canada/alberta', '_self')
        }}> <Image id='stateFlagMobile'  src='/albertaFlag.png'></Image>Alberta</Button>
          <Button className='clickable' size='sm' id='statesModalButtons'  onClick={() =>  {
         
          window.open('/canada/britishcolumbia', '_self')
        }}><Image id='stateFlagMobile'  src='/britishColumbiaFlag.png'></Image>British Columbia</Button>
         <Button className='clickable' size='sm' id='statesModalButtons'  onClick={() =>  {
         
          window.open('/canada/manitoba', '_self')
        }}> <Image id='stateFlagMobile'  src='/manitobaFlag.png'></Image>Manitoba</Button>
        </Row>
        <Row>
            <Button className='clickable' size='sm' id='statesModalButtons'  onClick={() =>  {
         
          window.open('/canada/newbrunswick', '_self')
        }}> <Image id='stateFlagMobile'  src='/newBrunswickFlag.png'></Image>New Brunswick</Button>
       
       <Button className='clickable' size='sm' id='statesModalButtons'  onClick={() =>  {
         
          window.open('/canada/newfoundland&labrador', '_self')
        }}> <Image id='stateFlagMobile'  src='/newfoundlandAndLabradorFlag.png'></Image>Newfoundland and Labrador</Button>
       
       <Button className='clickable' size='sm' id='statesModalButtons'  onClick={() =>  {
         
          window.open('/canada/northwestterritories', '_self')
        }}> <Image id='stateFlagMobile'  src='/northwestTerritoriesFlag.png'></Image>Northwest Territories</Button>
       </Row>
       <Row>
       <Button className='clickable' size='sm' id='statesModalButtons'  onClick={() =>  {
         
          window.open('/canada/novascotia', '_self')
        }}> <Image id='stateFlagMobile'  src='/novaScotiaFlag.png'></Image>Nova Scotia</Button>
        <Button className='clickable' size='sm' id='statesModalButtons'  onClick={() =>  {
         
          window.open('/canada/nunavut', '_self')
        }}> <Image id='stateFlagMobile'  src='/nunavutFlag.png'></Image>Nunavut</Button>
         <Button className='clickable' size='sm' id='statesModalButtons'  onClick={() =>  {
         
          window.open('/canada/ontario', '_self')
         }}> <Image id='stateFlagMobile'  src='/ontarioFlag.png'></Image>Ontario</Button>
        </Row>
        <Row>
         <Button className='clickable' size='sm' id='statesModalButtons'  onClick={() =>  {
         
          window.open('/canada/princeedwardisland', '_self')
        }}> <Image id='stateFlagMobile'  src='/princeEdwardIslandFlag.png'></Image>Prince Edward Island</Button>
         <Button className='clickable' size='sm' id='statesModalButtons'  onClick={() =>  {
         
          window.open('/canada/quebec', '_self')
        }}> <Image id='stateFlagMobile'  src='/quebecFlag.png'></Image>Quebec</Button>
         <Button className='clickable' size='sm' id='statesModalButtons'  onClick={() =>  {
         
          window.open('/canada/saskatchewan', '_self')
        }}> <Image id='stateFlagMobile'  src='/saskatchewanFlag.png'></Image>Saskatchewan</Button>
     
         <Button className='clickable' size='sm' id='statesModalButtons'  onClick={() =>  {
         
          window.open('/canada/yukon', '_self')
        }}> <Image id='stateFlagMobile'  src='/yukonFlag.png'></Image>Yukon</Button>
        </Row>

       
       
       
     
       </Col>
       </Container>
       </div>

</Modal>


  

 

  


  

  
  

  
  

</Nav>


</Navbar>

              </Row>
</Col>
        </Navbar>
           



          <Modal id='aboutModal'  show={declarationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDeclarationModalIsOpen(false)}>
            <div id='removedAlert'></div>
            
            <Modal.Header id='aboutModalHeader' closeButton ><h3>Declaration of Independence</h3></Modal.Header>


            <iframe src='https://gov-glance-congress-dailydigest.glitch.me/Declaration%20of%20Independence%20.html' height='800px' width='1138px' frameBorder='0'></iframe>

            <Modal.Footer><a href='https://www.archives.gov/founding-docs/declaration-transcript'>https://www.archives.gov/founding-docs/declaration-transcript</a></Modal.Footer>

          </Modal>


          <Modal id='aboutModal' show={constitutionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setConstitutionModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>Constitution</h3></Modal.Header>

            <iframe src='https://evening-plains-21303.herokuapp.com/https://constitution.congress.gov/constitution/' height='800px' width='1138px' frameBorder='0'></iframe>
            <Modal.Footer><a href='https://www.archives.gov/founding-docs/constitution-transcript'>https://www.archives.gov/founding-docs/constitution-transcript</a>
              <a href='https://constitution.congress.gov/constitution/'>https://constitution.congress.gov/constitution/</a>
            </Modal.Footer>
          </Modal>

          <Modal id='aboutModal' show={billOfRightsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillOfRightsModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>Bill of Rights</h3></Modal.Header>

            <iframe src='https://gov-glance-congress-dailydigest.glitch.me/The%20U%20S%20Bill%20of%20Rights.html' height='800px' width='1138px' frameBorder='0'></iframe>

            <Modal.Footer><a href='https://www.archives.gov/founding-docs/bill-of-rights-transcript'>https://www.archives.gov/founding-docs/bill-of-rights-transcript</a></Modal.Footer>

          </Modal>
   







          <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
  <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance is a civic engagement tool designed to help citizens 
                  get official government news in an easy-to-navigate centralized location. 
              There will never be any opinion or political analysis(That's your job), only information from official government 
              sources.
              </p>

              <p id='aboutParagraph'>
              Gov Glance Foundation Inc. is a non-profit dedicated to creating free educational tools and resources. <a id='aboutLink' href='https://govglance.foundation/'>https://govglance.foundation/</a>
              </p>
             
              <Button className='clickable' id='navbarAboutButtonDesktopMobile' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                      }}><HeartFill id='donateIcon'/>    Donate</Button>
            <br></br> <br></br>

            <h3>Privacy</h3>

            <br></br>

              <p id='aboutParagraph'>Gov Glance does not collect any personal user information. 
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC, 
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices 
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

              <br></br><br></br>

              For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>
            
            </p>
            <br></br>


            <h3>Contact</h3>

            <p id='aboutParagraph'>
                      Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

            </p>

            <br></br>


            
            </ModalBody>
          </Modal>

          

          <Modal id='collectionsModal' show={collectionsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setCollectionsModalIsOpen(false)}>
            <div id='removedAlert'></div>
            <Modal.Header id='collectionModalHeader' closeButton><h3>Collection</h3></Modal.Header>
            <CollectionPage />
          </Modal>







  
     


        </div>
        </div>
        </BrowserView>
        </div>
     


       
    </>

     
    
  );





                    }
                  }
                }




export default CanadaDesktopNavbar;
