import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch,  } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment,  } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Tooltip, OverlayTrigger, Image, ModalFooter } from 'react-bootstrap';
import { ArrowsAngleExpand, ArrowsFullscreen, InfoCircle, FileText,  ShieldShaded, CircleFill, Pen, People, ChatSquareQuote, ChatLeftQuote, BlockquoteRight, Collection, Grid, ThreeDots, Stack, List, Files, FolderPlus, FolderCheck, Folder, CaretRight, ChevronRight, ChevronCompactRight, CaretRightFill, ArrowRightShort,  ArrowRightCircle, PlayCircle, PauseCircle, HeartFill, Link45deg } from 'react-bootstrap-icons'
import { BrowserView, MobileView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import { render } from '@testing-library/react';
import { CreateFeedAPIArguments, CreateFeedAPIOpinions } from './Functions';
import GenerateFeedAudio from './GenerateFeedAudio';
import GenerateFeedOpinions from './GenerateFeedOpinions';
import JusticeDisplay from './JusticeDisplay';
import MobileTopNavbar from './MobileTopNavbar';
import MobileOffCanvas from './MobileOffCanvas';
import JusticeDisplay2 from './JusticeDisplay2';
import JusticeDisplay3 from './JusticeDisplay3';


const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);


var response = '';

var collectionCounter = 0
var carouselInterval = 30000;
var mobileCarouselInterval = 30000;


export const USSupremeCourtPageMobile = () =>   {
    render()

    {
        const [show, setShow] = useState(true);

        const [fullscreen, setFullScreen] = useState(true);
      
      
        const [fullBillModalIsOpen, setFullBillModalIsOpen] = useState(false)
      
        const [reportModalIsOpen, setReportModalIsOpen] = useState(false)
      
      
        const [whiteHouseFeedModalIsOpen, setWhiteHouseFeedModalIsOpen] = useState(false)
        const [whiteHouseDocsModalIsOpen, setWhiteHouseDocsModalIsOpen] = useState(false)
      
      
        const [courtNewsModalIsOpen, setCourtNewsModalIsOpen] = useState(false)
        const [courtModalIsOpen, setCourtModalIsOpen] = useState(false)
      
        const [courtAppealsModalIsOpen, setCourtAppealsModalIsOpen] = useState(false)
      
        const [newLawsModalIsOpen, setNewLawsModalIsOpen] = useState(false)
      
        const [billsEnrolledModalIsOpen, setBillsEnrolledModalIsOpen] = useState(false)
      
        const [legislativePageBillsEnrolledModalIsOpen, setLegislativePageBillsEnrolledModalIsOpen] = useState(false)
      
      
        const [departmentOfStateModalIsOpen, setDepartmentOfStateModalIsOpen] = useState(false)
        const [departmentOfTreasuryModalIsOpen, setDepartmentOfTreasuryModalIsOpen] = useState(false)
        const [departmentOfDefenseModalIsOpen, setDepartmentOfDefenseModalIsOpen] = useState(false)
        const [departmentOfJusticeModalIsOpen, setDepartmentOfJusticeModalIsOpen] = useState(false)
        const [departmentOfTheInteriorModalIsOpen, setDepartmentOfTheInteriorModalIsOpen] = useState(false)
        const [departmentOfAgricultureModalIsOpen, setDepartmentOfAgricultureModalIsOpen] = useState(false)
        const [departmentOfCommerceModalIsOpen, setDepartmentOfCommerceModalIsOpen] = useState(false)
        const [departmentOfLaborModalIsOpen, setDepartmentOfLaborModalIsOpen] = useState(false)
        const [departmentOfHealthAndHumanServicesModalIsOpen, setDepartmentOfHealthAndHumanServicesModalIsOpen] = useState(false)
        const [departmentOfHousingAndUrbanDevelopmentModalIsOpen, setDepartmentOfHousingAndUrbanDevelopmentModalIsOpen] = useState(false)
        const [departmentOfTransportationModalIsOpen, setDepartmentOfTransportationModalIsOpen] = useState(false)
        const [departmentOfEnergyModalIsOpen, setDepartmentOfEnergyModalIsOpen] = useState(false)
        const [departmentOfEducationModalIsOpen, setDepartmentOfEducationModalIsOpen] = useState(false)
        const [departmentOfVeteransAffairsModalIsOpen, setDepartmentOfVeteransAffairsModalIsOpen] = useState(false)
        const [departmentOfHomelandSecurityModalIsOpen, setDepartmentOfHomelandSecurityModalIsOpen] = useState(false)
        const [epaModalIsOpen, setEPAModalIsOpen] = useState(false)
        const [nsfModalIsOpen, setNSFModalIsOpen] = useState(false)
        const [nasaModalIsOpen, setNASAModalIsOpen] = useState(false)
        const [trendingBillsModalIsOpen, setTrendingBillsModalIsOpen] = useState(false)
        const [goaReportsModalIsOpen, setGAOReportsModalIsOpen] = useState(false)
        const [executiveOrdersModalIsOpen, setExecutiveOrdersModalIsOpen] = useState(false)
        const [presidentialProclamationsModalIsOpen, setPresidentialProclamationsModalIsOpen] = useState(false)
        const [statesModalIsOpen, setStatesModalIsOpen] = useState(false)
      
      
        const [collectionsModalIsOpen, setCollectionsModalIsOpen] = useState(false)
        const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
      
      
        const [federalRegisterDefenseDepartmentModalIsOpen, setFederalRegisterDefenseDepartmentModalIsOpen] = useState(false)
  const [federalRegisterStateDepartmentModalIsOpen, setFederalRegisterStateDepartmentModalIsOpen] = useState(false)
        const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false)
        const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false)
        const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false)
        const [whiteHouseBreifingModalIsOpen, setWhiteHouseBreifingModalIsOpen] = useState(false)
        const [presidentialDocumentsModalIsOpen, setPresidentialDocumentsModalIsOpen] = useState(false)
        const [stateDocumentsModalIsOpen, setStateDocumentsModalIsOpen] = useState(false)
        const [defenseDocumentsModalIsOpen, setDefenseDocumentsModalIsOpen] = useState(false)
        const [economicAdModalIsOpen, setEconomicAdModalIsOpen] = useState(false)
        const [environmentQualityModalIsOpen, setEnvironmentQualityModalIsOpen] = useState(false)
        const [domesticPolicyModalIsOpen, setDomesticPolicyModalIsOpen] = useState(false)
        const [genderPolicyModalIsOpen, setGenderPolicyModalIsOpen] = useState(false)
        const [nationalEconomicModalIsOpen, setNationalEconomicModalIsOpen] = useState(false)
        const [armyModalIsOpen, setArmyModalIsOpen] = useState(false)
        const [navyModalIsOpen, setNavyModalIsOpen] = useState(false)
        const [marinesModalIsOpen, setMarinesModalIsOpen] = useState(false)
        const [spaceForceModalIsOpen, setSpaceForceModalIsOpen] = useState(false)
        const [airForceModalIsOpen, setAirForceModalIsOpen] = useState(false)
        const [nationalGuardModalIsOpen, setNationalGuardModalIsOpen] = useState(false)
        const [ciaModalIsOpen, setCIAModalIsOpen] = useState(false)
        const [nsaModalIsOpen, setNSAModalIsOpen] = useState(false)

        const [coastGuardModalIsOpen, setCoastGuardModalIsOpen] = useState(false)
        const [femaModalIsOpen, setFEMAModalIsOpen] = useState(false)
        const [secretServiceModalIsOpen, setSecretServiceModalIsOpen] = useState(false)
        const [tsaModalIsOpen, setTSAModalIsOpen] = useState(false)
        const [immigrationModalIsOpen, setImmigrationModalIsOpen] = useState(false)
        const [usCustomsModalIsOpen, setUSCustomsModalIsOpen] = useState(false)
        const [enforcementsModalIsOpen, setEnforcemnetsModalIsOpen] = useState(false)
        const [homelandDocsModalIsOpen, setHomelandDocsModalIsOpen] = useState(false)

        
        // const [ModalIsOpen, setModalIsOpen] = useState(false)
        const [commerceDocsModalIsOpen, setCommerceDocsModalIsOpen] = useState(false)
        const [censusBureauModalIsOpen, setCensusBureauModalIsOpen] = useState(false)
        const [economicAnalysisModalIsOpen, setEconomicAnalysisModalIsOpen] = useState(false)
        const [ecoDevelopmentModalIsOpen, setEconDevelopmentModalIsOpen] = useState(false)
        const [counterterrorismModalIsOpen, setCounterterrorismModalIsOpen] = useState(false)
        const [democracyHumanRightsLaborModalIsOpen, setDemocracyHumanRightsLaborModalIsOpen] = useState(false)
        const [dOSPressModalIsOpen, setDOSPressModalIsOpen] = useState(false)
        const [diplomaticSecurityModalIsOpen, setDiplomaticSecurityModalIsOpen] = useState(false)
        const [armsControlModalIsOpen, setArmsControlModalIsOpen] = useState(false)
        const [agDocsModalIsOpen, setAgDocsModalIsOpen] = useState(false)
        const [eduDocumentsModalIsOpen, setEduDocumentsModalIsOpen] = useState(false)

        const [showBioModal, setBioModal] = useState(false);
    
        const [modalInfo, setModalInfo] = useState({
          isOpen: false,
          title: '',
          content: null
        });
      
        const openModal = (title, content) => {
          setModalInfo({
            isOpen: true,
            title,
            content
          });
        };
      
        const closeModal = () => {
          setModalInfo({
            isOpen: false,
            title: '',
            content: null
          });
        };
    
    var branch = "all";
  
    return(
       <>
   <MobileTopNavbar/>
  
  
  
  
  <div id='homebg'>

  <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
  
  <div id="feedDisplayDepartment" style={{ padding: '10px', borderRadius: '18px' }}>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div id="profileImageOnPageDepartment">
        <Image id="profileImage-imageOnPageDepartment" src="/judicialBranch.png" />
      </div>
      <div style={{ marginLeft: '10px', color: 'white', fontSize: '18px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" height="27.54px" viewBox="0 -960 960 960" width="27.54px" fill="#FFFFFF"><path d="M160-120v-80h480v80H160Zm226-194L160-540l84-86 228 226-86 86Zm254-254L414-796l86-84 226 226-86 86Zm184 408L302-682l56-56 522 522-56 56Z"/></svg>  */}
        <h5 id='branchHeadingMobile'>Supreme Court</h5>
      </div>
    </div>
  </div> 
  <div id="billText" style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>
  "EQUAL JUSTICE UNDER LAW" - These words, written above the main entrance to the Supreme Court Building, express the ultimate responsibility of the Supreme Court of the United States...
  <br></br>
  <Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
  </div>
</div>
  
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Supreme Court</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>"EQUAL JUSTICE UNDER LAW" - These words, written above the main entrance to the Supreme Court Building, express the ultimate responsibility of the Supreme Court of the United States. The Court is the highest tribunal in the Nation for all cases and controversies arising under the Constitution or the laws of the United States. As the final arbiter of the law, the Court is charged with ensuring the American people the promise of equal justice under law and, thereby, also functions as guardian and interpreter of the Constitution.</p>

<p>The Supreme Court consists of the Chief Justice of the United States and such number of Associate Justices as may be fixed by Congress. The number of Associate Justices is currently fixed at eight (28 U.S.C. §1). Power to nominate the Justices is vested in the President of the United States, and appointments are made with the advice and consent of the Senate.</p>

<p><strong>Chief Justice of the United States</strong></p>

<p><strong>Associate Justices</strong></p>

<p><strong>Retired Justices</strong></p>

<p>Court Officers assist the Court in the performance of its functions. They include the Counselor to the Chief Justice, the Clerk, the Librarian, the Marshal, the Reporter of Decisions, the Court Counsel, the Curator, the Director of Information Technology, and the Public Information Officer.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.supremecourt.gov/about/about.aspx/", "_blank");
   }}>
     https://www.supremecourt.gov/about/about.aspx/
     </Link>
 </div>

       </Modal>  


       <Col>


       <Link  id='pageLink' to="/us/justices"> <h5 id='presidentialFeedHeadingMobile'>Justices<ChevronRight id='clickChevron'></ChevronRight></h5></Link>      
                <Carousel controls={false} >

                <Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<JusticeDisplay/>
 
    </Col>

    </div>
   


</Carousel.Item>


  <Carousel.Item interval={mobileCarouselInterval}>

    <div id='legislativeConMobile'>

    <Col>
<JusticeDisplay2/>
 
    </Col>

                


        
      
        </div>
       


</Carousel.Item>

<Carousel.Item interval={mobileCarouselInterval}>

    <div id='legislativeConMobile'>

    <Col>
<JusticeDisplay3/>
 
    </Col>

                


        
      
        </div>
       


</Carousel.Item>



<Carousel.Item interval={mobileCarouselInterval}>

<div id='legislativeConMobile'>

<Col>
<div id='viewFullPageButton'>
<Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/justices' >View all Justices</Button>
</div>
</Col>

</div>



</Carousel.Item>


</Carousel>

</Col> 


       {/* <Col>                                    
                                      <Link  id='pageLink' to="/us/justices"> <h5 id='presidentialFeedHeadingMobile'>Justices<ChevronRight id='clickChevron'></ChevronRight></h5></Link>
                          

                          
                          <JusticeDisplay/>
                     
             </Col> */}
       

             <Col>

<h5 id='presidentialFeedHeadingMobile' onClick={() =>
            openModal('Supreme Court Opinions',
            <GenerateFeedOpinions url='&skip=0&schema=united_states_of_america&table=supreme_court_opinions&order_by=created_at' 
            name='Supreme Court Opinions' branch='Judicial' topic='N/A' buttonName='Document' />)}
            >Supreme Court Opinions</h5>
<div id='courtOpinions'></div>
{CreateFeedAPIOpinions('&skip=0&schema=united_states_of_america&table=supreme_court_opinions&order_by=created_at', 'courtOpinions', 
            'Supreme Court Opinions', 'Judicial', 
            'N/A', 'Document', GenerateFeedOpinions)}
</Col>








<Col>

<h5 id='presidentialFeedHeadingMobile' onClick={() =>
            openModal('Supreme Court Arguments',
            <GenerateFeedAudio url='&skip=0&schema=united_states_of_america&table=supreme_court_arguments&order_by=created_at' 
            name='Supreme Court Arguments' branch='Judicial' topic='N/A' buttonName='Document' />)}>Supreme Court Arguments</h5>
<div id='courtArguments'></div>
{CreateFeedAPIArguments('&skip=0&schema=united_states_of_america&table=supreme_court_arguments&order_by=created_at', 'courtArguments', 
            'Supreme Court Arguments', 'Judicial', 
            'N/A', 'Document', GenerateFeedAudio)}



</Col>





            <Col>

            </Col>

            <Col>
            <br></br><br></br><br></br><br></br>
<br></br>
<br></br>
<h5 id='headingSelectHomeCountry'>Support the Gov Glance Foundation  <Button id='donateButton' onClick={(e) => {
                        e.preventDefault();
                        window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288')
                      }}><HeartFill id='donateIcon'/>    Donate</Button></h5>

</Col>
          
            <br></br><br></br><br></br><br></br><br></br><br></br>
            <Navbar id='navbarMobileBottom' fixed="bottom">    

<div id='changeBranchDropdownMobile'>
       
           <MobileOffCanvas/>
              
            </div>

</Navbar>
          </div>

          </>
  )
}
 }



  






export default USSupremeCourtPageMobile;

