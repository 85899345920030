import { useEffect, useState } from "react";
import { Button, Card, Container, Form, Image, Modal, Navbar, Row, Spinner } from "react-bootstrap";
import { Files, FileText, FolderPlus, InfoCircle, Search } from "react-bootstrap-icons";
import { BrowserView, MobileView } from "react-device-detect";
import { Link, Redirect } from "react-router-dom";
import MobileOffCanvas from "./MobileOffCanvas";
import Presidents from "./Presidents";

var storedTitle = ''// title
var storedDetailsLink = ''// details link
var storedDocLink = ''// doc link *
var storedDate = ''// date
var storedNote = ''// note
var storedFeedName = ''// feed name
var storedBranchName = ''// branch name 
var storedTopicName = ''// topic name
var storedDescription = ''// description
var collectionCounter = localStorage.getItem('collectionNumber')

function formatDate(inputDate) {
  const date = new Date(inputDate);
  
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}

function SaveFeedItem(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, 
  saveCollectionNote, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription)
{

storedTitle = saveCollectionTitle;
storedDetailsLink = saveCollectionDetailLink;
storedDocLink = saveCollectionDocLink;
storedDate = saveCollectionDate;
storedNote = saveCollectionNote;
storedFeedName = saveCollectionFeedName;
storedBranchName = saveCollectionBranchName;
storedTopicName = saveCollectionTopicName;
storedDescription = saveCollectionDescription;
}

function getNumberSuffix(number) {
  if (number % 100 >= 11 && number % 100 <= 13) {
    return `${number}th`;
  }
  switch (number % 10) {
    case 1:
      return `${number}st`;
    case 2:
      return `${number}nd`;
    case 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
}

function PresidentPage(props) {
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const [showModal4, setShowModal4] = useState(false);
    const [showBioModal, setBioModal] = useState(false);
    const [searchQueryModal, setSearchQueryModal] = useState('');
    const [isSearchTriggered, setIsSearchTriggered] = useState(false);
    function saveNote(saveCollectionTitle, saveCollectionDetailLink, saveCollectionDocLink, saveCollectionDate, saveCollectionFeedName, saveCollectionBranchName, saveCollectionTopicName, saveCollectionDescription ){
    
      var searchItem = document.getElementById('noteModalFeedItem').value
    
         collectionCounter = localStorage.getItem('collectionNumber')
     
       collectionCounter++;
  
       
       var collectionTitles = []
        var collectionDetailLinks = []
       var collectionDocLinks = []
       var collectionNote = []
  
         var title = 'title'.concat(collectionCounter)
         var detailLink = 'detailLink'.concat(collectionCounter)
         var docLink = 'docLink'.concat(collectionCounter)
         var itemNote = 'itemNote'.concat(collectionCounter)
         var itemDate = 'itemDate'.concat(collectionCounter)
         var feedName = 'feedName'.concat(collectionCounter)
         var branchName = 'branchName'.concat(collectionCounter)
         var topicName = 'topicName'.concat(collectionCounter)
         var itemDescription = 'itemDescription'.concat(collectionCounter)
     
     
       
       
         localStorage.setItem(title,saveCollectionTitle)
         
         localStorage.setItem(detailLink,saveCollectionDetailLink)
        
         localStorage.setItem(docLink,saveCollectionDocLink)
         localStorage.setItem(itemNote,searchItem)
     
         localStorage.setItem(itemDate,saveCollectionDate)
         localStorage.setItem(feedName,saveCollectionFeedName)
         localStorage.setItem(branchName,saveCollectionBranchName)
         localStorage.setItem(topicName,saveCollectionTopicName)
     
         localStorage.setItem(itemDescription,saveCollectionDescription)
       
         localStorage.setItem('collectionNumber', collectionCounter)
  
    }
  
    const [collectionModalIsOpen, setCollectionModalIsOpen] = useState(false)
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);
  const [error3, setError3] = useState(null);
  const [error4, setError4] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
    require('dotenv').config();
    const apiKey = process.env.REACT_APP_API_KEY;
    const requestOptions = {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json',
        "Authorization": "Bearer "+apiKey,
    },
    };

    const [stateOfUnion, setStateOfUnion] = useState(null);
    const [stateOfUnionModal, setStateOfUnionModal] = useState(null);
    const [presidentDocs, setPresidentDocs] = useState(null);
    const [presidentDocsModal, setPresidentDocsModal] = useState(null);
    const [memberData, setMemberData] = useState(null);
    const [memberDataModal, setMemberDataModal] = useState(null);
    const [laws, setLaws] = useState(null);
    const [lawsModal, setLawsModal] = useState(null);
    const { location } = props;

    // Check if props.location.state is undefined or any required properties are missing
  
    const name = props.location.state.name;

    
    const termStart = props.location.state.termStart;
    const termEnd = props.location.state.termEnd;
    const presidentNumber = props.location.state.presidentNumber;
    const imageSource = props.location.state.imageSource;
    const description = props.location.state.description;
    //console.log(description)
    const handleViewMoreClick = () => {

      setShowModal(true);
    
    }

    
    
    const truncateDescription = (description, maxLength) => {
      if (description.length > maxLength) {
          return description.substring(0, maxLength) + '...';
      }
      return description;
  };

  const truncatedDescription = truncateDescription(description, 200);

    const renderEndTerm = () => {
      const formattedEndTerm = formatDate(termEnd);
      if (formattedEndTerm === "12-31-1969") {
        return "Present";
      } else {
        return formattedEndTerm;
      }
    };

    useEffect(() => {
     
        const fetchMemberData = async () => {
          try {
            setLoading(true); // Set loading to true when fetching data
      
            const url = `https://api.govglance.org/members/executive_orders?order_by=executive_order_number&limit=2&skip=0&president=${name}`;
            const response = await fetch(url, requestOptions);
            //console.log(url)
            if (!response.ok) {
              throw new Error('Currently Unavailable');
            }
      
            const data = await response.json();
            setMemberData(data);
           
            setLoading(false); // Set loading to false after data fetching is completed
          } catch (error) {
            console.error('Error fetching member data:', error);
           setError(error);
            setLoading(false); // Set loading to false in case of an error
          }
        };
      
        fetchMemberData();
      }, []); 
      
      useEffect(() => {
        const fetchMemberDataModal = async () => {
          try {
            setLoading(true); // Set loading to true when fetching data
      
            const url = `https://api.govglance.org/members/executive_orders?order_by=executive_order_number&limit=300&skip=0&president=${name}`;
            const response = await fetch(url, requestOptions);
         //   console.log(url)
            if (!response.ok) {
              throw new Error('Currently Unavailable');
            }
      
            const data = await response.json();
            setMemberDataModal(data);
           // console.log(data)
            setLoading(false); // Set loading to false after data fetching is completed
          } catch (error) {
            console.error('Error fetching member data:', error);
           setError(error);
            setLoading(false); // Set loading to false in case of an error
          }
        };
      
        fetchMemberDataModal();
      }, []); 

      const renderDataModal = () => {

        const filteredDataModal = isSearchTriggered
  ? memberDataModal.filter((item) =>
      item.title.toLowerCase().includes(searchQueryModal.toLowerCase())
    )
  : memberDataModal;

        if (loading) {
          return <Spinner animation="grow" variant="light" />;
        }
      
        if (error) {
          return <p id='billText'>Currently Unavailable</p>;
        }
      
        if (!memberDataModal) {
          return  <p id='billText'>Currently Unavailable</p>; // or any placeholder message if needed
        }
      
        
      
        return filteredDataModal.map((item, index) => {
          return (
            <div key={index}>
              {item && (
                <>
                  <Container id='feedContainerNormalView'>
                  <Card.Title onClick={(e) => {
  e.preventDefault();
 
  window.open(item.pdf_url, "_blank");
}} id='billText'>{item.title} </Card.Title>
                  <Card.Body id='billBodyText'>{formatDate(item.created_at)}</Card.Body>
                  <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(item.url, "_blank");
            }}><InfoCircle></InfoCircle> Details</Button>
{item.pdf_url && (
<Button id='viewBillButton' onClick={(e) => {
  e.preventDefault();
 
  window.open(item.pdf_url, "_blank");
}}>
  <FileText></FileText> Order
</Button>
)}
<Button id='addToCollectionButton' onClick={() => {
              setCollectionModalIsOpen(true);
              SaveFeedItem(item.title, item.url, item.pdf_url, formatDate(item.signing_date), '', "Executive Orders", "Executive", "N/A", "");
            }}><FolderPlus></FolderPlus></Button>

                  </Container>
                  <br></br>
                </>
              )}
            </div>
          
          );
          
        });
      }
      const renderData = () => {
        if (loading) {
          return <Spinner animation="grow" variant="light" />;
        }
      
        if (error) {
          return <p id='billText'>Currently Unavailable</p>;
        }
      
        if (!memberData) {
          return  <p id='billText'>Currently Unavailable</p>; // or any placeholder message if needed
        }
      
       // console.log('Member Data:', memberData); // Log memberData for debugging
      
        return memberData.map((item, index) => {
          return (
            <div key={index}>
              {item && (
                <>
                  <Container id='feedContainerNormalView'>
                  <Card.Title onClick={(e) => {
  e.preventDefault();
 
  window.open(item.pdf_url, "_blank");
}} id='billText'>{item.title} </Card.Title>
                  <Card.Body id='billBodyText'>{formatDate(item.created_at)}</Card.Body>
                  <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(item.url, "_blank");
            }}><InfoCircle></InfoCircle> Details</Button>
{item.pdf_url && (
<Button id='viewBillButton' onClick={(e) => {
  e.preventDefault();
 
  window.open(item.pdf_url, "_blank");
}}>
  <FileText></FileText> Order
</Button>
)}
<Button id='addToCollectionButton' onClick={() => {
              setCollectionModalIsOpen(true);
              SaveFeedItem(item.title, item.url, item.pdf_url, formatDate(item.signing_date), '', "Executive Orders", "Executive", "N/A", "");
            }}><FolderPlus></FolderPlus></Button>

                  </Container>
                  <br></br>
                </>
              )}
            </div>
          
          );
          
        });
      }

      /*Pres Docs */
      useEffect(() => {
        const fetchPresidentDocs= async () => {
          try {
            setLoading(true); // Set loading to true when fetching data
      
            const url = `https://api.govglance.org/members/presidential_documents?order_by=created_at&limit=2&skip=0&president=${name}`;
            const response = await fetch(url, requestOptions);
            //console.log(url)
            if (!response.ok) {
              throw new Error('Currently Unavailable');
            }
      
            const data = await response.json();
            setPresidentDocs(data);
          // console.log(data)
            setLoading(false); // Set loading to false after data fetching is completed
          } catch (error) {
            console.error('Error fetching member data:', error);
           setError2(error);
            setLoading(false); // Set loading to false in case of an error
          }
        };
      
        fetchPresidentDocs();
      }, []); 

      useEffect(() => {
        const fetchPresidentDocsModal = async () => {
          try {
            setLoading(true); // Set loading to true when fetching data
      
            const url = `https://api.govglance.org/members/presidential_documents?order_by=created_at&limit=300&skip=0&president=${name}`;
            const response = await fetch(url, requestOptions);
            //console.log(url)
            if (!response.ok) {
              throw new Error('Currently Unavailable');
            }
      
            const data = await response.json();
            setPresidentDocsModal(data);
          // console.log(data)
            setLoading(false); // Set loading to false after data fetching is completed
          } catch (error) {
            console.error('Error fetching member data:', error);
           setError2(error);
            setLoading(false); // Set loading to false in case of an error
          }
        };
      
        fetchPresidentDocsModal();
      }, []); 

      const presidentialDocs = () => {
        if (loading) {
          return <Spinner animation="grow" variant="light" />;
        }
      
        if (error2) {
          return <p id='billText'>Currently Unavailable</p>;
        }
      
        if (!presidentDocs) {
          return  <p id='billText'>Currently Unavailable</p>; // or any placeholder message if needed
        }
      
       // console.log('Member Data:', memberData); // Log memberData for debugging
      
        return presidentDocs.map((item, index) => {
          return (
            <div key={index}>
              {item && (
                <>
                  <Container id='feedContainerNormalView'>
                  <Card.Title onClick={(e) => {
  e.preventDefault();
 
  window.open(item.download.pdfLink, "_blank")
}} id='billText'>{item.title} </Card.Title>
                  <Card.Body id='billBodyText'>{formatDate(item.date_issued)}</Card.Body>
                  <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(item.url, "_blank");
            }}><InfoCircle></InfoCircle> Details</Button>
{item.download.pdfLink && (
<Button id='viewBillButton' onClick={(e) => {
  e.preventDefault();
 
  window.open(item.download.pdfLink, "_blank")
}}>
  <FileText></FileText> Doc
</Button>
)}
<Button id='addToCollectionButton' onClick={() => {
              setCollectionModalIsOpen(true);
              SaveFeedItem(item.title, item.url, item.download.pdfLink, formatDate(item.date_issued), '', "Presidential Documents", "Executive", "N/A", "");
            }}><FolderPlus></FolderPlus></Button>

                  </Container>
                  <br></br>
                </>
              )}
            </div>
          
          );
          
        });
      }

      const presidentialDocsModal = () => {
        if (loading) {
          return <Spinner animation="grow" variant="light" />;
        }
      
        if (error2) {
          return <p id='billText'>Currently Unavailable</p>;
        }
      
        if (!presidentDocsModal) {
          return  <p id='billText'>Currently Unavailable</p>; // or any placeholder message if needed
        }
      
       // console.log('Member Data:', memberData); // Log memberData for debugging
      
        return presidentDocsModal.map((item, index) => {
          return (
            <div key={index}>
              {item && (
                <>
                  <Container id='feedContainerNormalView'>
                  <Card.Title onClick={(e) => {
  e.preventDefault();
 
  window.open(item.download.pdfLink, "_blank")
}} id='billText'>{item.title} </Card.Title>
                  <Card.Body id='billBodyText'>{formatDate(item.date_issued)}</Card.Body>
                  <Button id='billLink' onClick={(e) => {
              e.preventDefault();
              window.open(item.url, "_blank");
            }}><InfoCircle></InfoCircle> Details</Button>
{item.download.pdfLink && (
<Button id='viewBillButton' onClick={(e) => {
  e.preventDefault();
 
  window.open(item.download.pdfLink, "_blank")
}}>
  <FileText></FileText> Doc
</Button>
)}
<Button id='addToCollectionButton' onClick={() => {
              setCollectionModalIsOpen(true);
              SaveFeedItem(item.title, item.url, item.download.pdfLink, formatDate(item.date_issued), '', "Presidential Documents", "Executive", "N/A", "");
            }}><FolderPlus></FolderPlus></Button>

                  </Container>
                  <br></br>
                </>
              )}
            </div>
          
          );
          
        });
      }


        /*State of The Union */
        useEffect(() => {
          const fetchPresidentStateOfUnion= async () => {
            try {
              setLoading(true); // Set loading to true when fetching data
        
              const url = `https://api.govglance.org/members/state_of_the_union?order_by=created_at&limit=1&skip=0&president=${name}`;
              const response = await fetch(url, requestOptions);
              //console.log(url)
              if (!response.ok) {
                throw new Error('Currently Unavailable');
              }
        
              const data = await response.json();
              setStateOfUnion(data);
            // console.log(data)
              setLoading(false); // Set loading to false after data fetching is completed
            } catch (error) {
              console.error('Error fetching member data:', error);
             setError3(error);
              setLoading(false); // Set loading to false in case of an error
            }
          };
        
          fetchPresidentStateOfUnion();
        }, []); 

        useEffect(() => {
          const fetchPresidentStateOfUnionModal = async () => {
            try {
              setLoading(true); // Set loading to true when fetching data
        
              const url = `https://api.govglance.org/members/state_of_the_union?order_by=created_at&limit=10&skip=0&president=${name}`;
              const response = await fetch(url, requestOptions);
              //console.log(url)
              if (!response.ok) {
                throw new Error('Currently Unavailable');
              }
        
              const data = await response.json();
              setStateOfUnionModal(data);
            // console.log(data)
              setLoading(false); // Set loading to false after data fetching is completed
            } catch (error) {
              console.error('Error fetching member data:', error);
             setError3(error);
              setLoading(false); // Set loading to false in case of an error
            }
          };
        
          fetchPresidentStateOfUnionModal();
        }, []); 

        const stateOfTheUnion = () => {
          if (loading) {
            return <Spinner animation="grow" variant="light" />;
          }
        
          if (error3) {
            return <p id='billText'>Currently Unavailable</p>;
          }
        
          if (!stateOfUnion) {
            return  <p id='billText'>Currently Unavailable</p>; // or any placeholder message if needed
          }
        
         // console.log('Member Data:', memberData); // Log memberData for debugging
        
          return stateOfUnion.map((item, index) => {
            return (
              <div key={index}>
                {item && (
                  <>
                    <Container id='feedContainerNormalView'>
                    <Card.Title  onClick={(e) => {
                e.preventDefault();
                window.open(item.url, "_blank");
              }} id='billText'>{item.title} </Card.Title>
                    <Card.Body id='billBodyText'>{formatDate(item.created_at)}</Card.Body>
                    <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(item.url, "_blank");
              }}><InfoCircle></InfoCircle> Details</Button>

 

  
  <Button id='addToCollectionButton' onClick={() => {
                setCollectionModalIsOpen(true);
                SaveFeedItem(item.title, item.url, null, formatDate(item.created_at), '', "State of the Union Address", "Executive", "N/A", "");
              }}><FolderPlus></FolderPlus></Button>
  
                    </Container>
                    <br></br>
                  </>
                )}
              </div>
            
            );
            
          });
        }

        const stateOfTheUnionModal = () => {
          if (loading) {
            return <Spinner animation="grow" variant="light" />;
          }
        
          if (error3) {
            return <p id='billText'>Currently Unavailable</p>;
          }
        
          if (!stateOfUnionModal) {
            return  <p id='billText'>Currently Unavailable</p>; // or any placeholder message if needed
          }
        
         // console.log('Member Data:', memberData); // Log memberData for debugging
        
          return stateOfUnionModal.map((item, index) => {
            return (
              <div key={index}>
                {item && (
                  <>
                    <Container id='feedContainerNormalView'>
                    <Card.Title  onClick={(e) => {
                e.preventDefault();
                window.open(item.url, "_blank");
              }} id='billText'>{item.title} </Card.Title>
                    <Card.Body id='billBodyText'>{formatDate(item.created_at)}</Card.Body>
                    <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(item.url, "_blank");
              }}><InfoCircle></InfoCircle> Details</Button>
 
 

  <Button id='addToCollectionButton' onClick={() => {
                setCollectionModalIsOpen(true);
                SaveFeedItem(item.title, item.url, null, formatDate(item.created_at), '', "State of the Union Address", "Executive", "N/A", "");
              }}><FolderPlus></FolderPlus></Button>
  
                    </Container>
                    <br></br>
                  </>
                )}
              </div>
            
            );
            
          });
        }

        useEffect(() => {
          const fetchPresidentLaws= async () => {
            try {
              setLoading(true); // Set loading to true when fetching data
        
              const url = `https://api.govglance.org/members/public_private_laws?limit=2&skip=0&president=${name}`;
              const response = await fetch(url, requestOptions);
              //console.log(url)
              if (!response.ok) {
                throw new Error('Currently Unavailable');
              }
        
              const data = await response.json();
              setLaws(data);
            // console.log(data)
              setLoading(false); // Set loading to false after data fetching is completed
            } catch (error) {
              console.error('Error fetching member data:', error);
             setError4(error);
              setLoading(false); // Set loading to false in case of an error
            }
          };
        
          fetchPresidentLaws();
        }, []); 
    
        useEffect(() => {
          const fetchPresidentLawsModal= async () => {
            try {
              setLoading(true); // Set loading to true when fetching data
        
              const url = `https://api.govglance.org/members/public_private_laws?limit=50&skip=0&president=${name}`;
              const response = await fetch(url, requestOptions);
              //console.log(url)
              if (!response.ok) {
                throw new Error('Currently Unavailable');
              }
        
              const data = await response.json();
              setLawsModal(data);
            // console.log(data)
              setLoading(false); // Set loading to false after data fetching is completed
            } catch (error) {
              console.error('Error fetching member data:', error);
             setError4(error);
              setLoading(false); // Set loading to false in case of an error
            }
          };
        
          fetchPresidentLawsModal();
        }, []); 
    
    
        const presidentLaws = () => {
          if (loading) {
            return <Spinner animation="grow" variant="light" />;
          }
        
          if (error4) {
            return <p id='billText'>Currently Unavailable</p>;
          }
        
          if (!laws) {
             return <p id='billText'>Currently Unavailable</p>// or any placeholder message if needed
          }
        
         // console.log('Member Data:', memberData); // Log memberData for debugging
        
          return laws.map((item, index) => {
            return (
              <div key={index}>
                {item && (
                  <>
                    <Container id='feedContainerNormalView'>
                    <Card.Title  onClick={(e) => {
                    e.preventDefault();
                    window.open(item.url, "_blank");
                  }} id='billText'>{item.title} </Card.Title>
                    <Card.Body id='billBodyText'>{formatDate(item.created_at)}</Card.Body>
                    <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(item.url, "_blank");
              }}><InfoCircle></InfoCircle> Details</Button>
    
    
    
    
    <Button id='addToCollectionButton' onClick={() => {
                setCollectionModalIsOpen(true);
                SaveFeedItem(item.title, item.url, null, formatDate(item.created_at), '', "State of the Union Address", "Executive", "N/A", "");
              }}><FolderPlus></FolderPlus></Button>
    
                    </Container>
                    <br></br>
                  </>
                )}
              </div>
            
            );
            
          });
        }
    
        const presidentLawsModal = () => {
          if (loading) {
            return <Spinner animation="grow" variant="light" />;
          }
        
          if (error4) {
            return <p id='billText'>Currently Unavailable</p>;
          }
        
          if (!lawsModal) {
             return <p id='billText'>Currently Unavailable</p>// or any placeholder message if needed
          }
        
         // console.log('Member Data:', memberData); // Log memberData for debugging
        
          return lawsModal.map((item, index) => {
            return (
              <div key={index}>
                {item && (
                  <>
                    <Container id='feedContainerNormalView'>
                    <Card.Title  onClick={(e) => {
                    e.preventDefault();
                    window.open(item.url, "_blank");
                  }} id='billText'>{item.title} </Card.Title>
                    <Card.Body id='billBodyText'>{formatDate(item.created_at)}</Card.Body>
                    <Button id='billLink' onClick={(e) => {
                e.preventDefault();
                window.open(item.url, "_blank");
              }}><InfoCircle></InfoCircle> Details</Button>
    
    
    
    
    <Button id='addToCollectionButton' onClick={() => {
                setCollectionModalIsOpen(true);
                SaveFeedItem(item.title, item.url, null, formatDate(item.created_at), '', "State of the Union Address", "Executive", "N/A", "");
              }}><FolderPlus></FolderPlus></Button>
    
                    </Container>
                    <br></br>
                  </>
                )}
              </div>
            
            );
            
          });
        }
             
      
    return(
        <>
        <BrowserView>
        <div id='homebg'>
               
               <br></br>
<div id='feedDisplay' 
                  style={{ padding: '20px', borderRadius: '18px', marginTop: '0em'}}>

<div style={{ position: 'relative' }}>
   {imageSource && (
     <div id='profileImage' style={{ width: '150px', height: '150px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '60px' }}>
     <Image id='profileImage-image' src={imageSource} />
   </div>
   )}

   <div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
   <div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>
<span>{name}</span>
</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span>{getNumberSuffix(presidentNumber)} U.S. President</span>
<br></br>
<span>From {formatDate(termStart)} To {renderEndTerm()}</span>
<span>     <div dangerouslySetInnerHTML={{ __html: truncatedDescription }} /> </span>

     </div>
     </div>


     </div>
     <br></br>
     <Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
<br></br>
<br></br>
<br></br>
<Card.Body  id='cardBodyRep' onClick={() =>  setShowModal3(true)}><h3>State of the Union Address</h3></Card.Body>
      

      {stateOfTheUnion()}
      <Button id='whDocsViewMoreButton' onClick={() =>  setShowModal3(true)}>  <Files size={20}></Files> View More</Button>
<br></br>
<br></br>
<Card.Body  id='cardBodyRep' onClick={() =>  setShowModal(true)}><h3>Executive Orders</h3></Card.Body>
      

           {renderData()}
           <Button id='whDocsViewMoreButton' onClick={() =>  setShowModal(true)}>  <Files size={20}></Files> View More</Button>

<br></br>
<br></br>
           <Card.Body  id='cardBodyRep' onClick={() =>  setShowModal2(true)}><h3>Presidential Documents</h3></Card.Body>
      

      {presidentialDocs()}
      <Button id='whDocsViewMoreButton' onClick={() =>  setShowModal2(true)}>  <Files size={20}></Files> View More</Button>

      <br></br>
<br></br>
           <Card.Body  id='cardBodyRep' onClick={() =>  setShowModal4(true)}><h3>Laws</h3></Card.Body>
      

      {presidentLaws()}
      <Button id='whDocsViewMoreButton' onClick={() =>  setShowModal4(true)}>  <Files size={20}></Files> View More</Button>



      <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal4} onHide={() => setShowModal4(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3> {name}'s Laws</h3></Modal.Header>
          <div id='searchForm'>
        <Form className="text-center">
          <Form.Group className="d-flex justify-content-center align-items-center">
          <Form.Control
  variant="light"
  id="searchInput"
  placeholder="Search..."
  type="text"
  color="white"
  value={searchQueryModal}
  onChange={(e) => setSearchQueryModal(e.target.value)}
/>
            <Button id='ggButton' onClick={() => setIsSearchTriggered(true)} >
              <Search /> 
            </Button>
          </Form.Group>
        </Form>
      </div>
          <div id='fullBillFeed' >
       
            {presidentLawsModal()}

  </div>
        </Modal>
      <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> {name}'s Biography</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <div dangerouslySetInnerHTML={{ __html: description }} /> 

 </div>

       </Modal>

      <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal2} onHide={() => setShowModal2(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> {name}'s Presidential Documents</h3></Modal.Header>
         <div id='searchForm'>
       <Form className="text-center">
         <Form.Group className="d-flex justify-content-center align-items-center">
         <Form.Control
 variant="light"
 id="searchInput"
 placeholder="Search..."
 type="text"
 color="white"
 value={searchQueryModal}
 onChange={(e) => setSearchQueryModal(e.target.value)}
/>
           <Button id='ggButton' onClick={() => setIsSearchTriggered(true)} >
             <Search /> 
           </Button>
         </Form.Group>
       </Form>
     </div>
         <div id='fullBillFeed' >
      
           {presidentialDocsModal()}

 </div>
       </Modal>

       <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal3} onHide={() => setShowModal3(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> {name}'s State of the Union Addresses</h3></Modal.Header>
         <div id='searchForm'>
       <Form className="text-center">
         <Form.Group className="d-flex justify-content-center align-items-center">
         <Form.Control
 variant="light"
 id="searchInput"
 placeholder="Search..."
 type="text"
 color="white"
 value={searchQueryModal}
 onChange={(e) => setSearchQueryModal(e.target.value)}
/>
           <Button id='ggButton' onClick={() => setIsSearchTriggered(true)} >
             <Search /> 
           </Button>
         </Form.Group>
       </Form>
     </div>
         <div id='fullBillFeed' >
      
           {stateOfTheUnionModal()}

 </div>
       </Modal>

           



           <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={() => setShowModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> {name}'s Executive Orders</h3></Modal.Header>
         <div id='searchForm'>
       <Form className="text-center">
         <Form.Group className="d-flex justify-content-center align-items-center">
         <Form.Control
 variant="light"
 id="searchInput"
 placeholder="Search..."
 type="text"
 color="white"
 value={searchQueryModal}
 onChange={(e) => setSearchQueryModal(e.target.value)}
/>
           <Button id='ggButton' onClick={() => setIsSearchTriggered(true)} >
             <Search /> 
           </Button>
         </Form.Group>
       </Form>
     </div>
         <div id='fullBillFeed' >
      
           {renderDataModal()}

 </div>
       </Modal>



           <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
centered onHide={setCollectionModalIsOpen}>
                            
                             <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>

                             <Modal.Body id='descriptionModalBody'>
                                         {storedDescription}
                                         {storedDate}
                             <div id='fullBill'>
                        <Form id='noteModalForm'>
        <Form.Group id='noteModalForm'  className="mb-3" >
          <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
            type="text"
            as={"textarea"}
            rows={2}
            />
            <br></br>

        </Form.Group>
        </Form>

        <Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
 storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
   Save To Collection
 </Button>
 </Modal.Footer> 

          </div>
          </Modal.Body>
                             </Modal>
        
           </div>
           </div>
            </BrowserView>
            <MobileView>
            <div id='homebg'>
               
                <br></br>
                <div id='feedDisplay'
style={{ padding: '20px', borderRadius: '18px', marginTop: '10px', paddingTop: '8px'}}>

<div style={{display: 'flex',
                flexDirection: 'row'}}>
    {imageSource && (
      <div id='profileImageOnPage'>
      <Image id='profileImage-imageOnPage' src={imageSource} />
    </div>
    )}

    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
    <div style={{color: 'white', fontSize: '18px',
                  fontWeight: 'bold'}}>
<span>{name}</span>
</div>
<div  style={{color: 'white', fontSize: '16px'}}> 
 <span>{getNumberSuffix(presidentNumber)} U.S. President</span>
 <br></br>
 <span>From {formatDate(termStart)} To {renderEndTerm()}</span>


</div>
</div>


</div>
<div style={{height: '20px',}}></div>
<div dangerouslySetInnerHTML={{ __html: truncatedDescription }} /> 
<Button id='presidentViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
<br></br>

<br></br>

<div  style={{color: 'white', fontSize: '18px',
                  fontWeight: 'bold'}} onClick={() =>  setShowModal3(true)}>State of the Union Address</div>
      <br></br>

      {stateOfTheUnion()}
      <Button id='presidentViewMoreButton' onClick={() =>  setShowModal3(true)}>  <Files size={20}></Files> View More</Button>

      <br></br>
      <br></br>
<div style={{color: 'white', fontSize: '18px',
                  fontWeight: 'bold'}} onClick={() =>  setShowModal(true)}>Executive Orders</div>
       <br></br>

            {renderData()}
            <Button id='presidentViewMoreButton' onClick={() =>  setShowModal(true)}>  <Files size={20}></Files> View More</Button>

<br></br>
<br></br>
            <div style={{color: 'white', fontSize: '18px',
                  fontWeight: 'bold'}} onClick={() =>  setShowModal2(true)}>Presidential Documents</div>
       <br></br>

       {presidentialDocs()}
       <Button id='presidentViewMoreButton' onClick={() =>  setShowModal2(true)}>  <Files size={20}></Files> View More</Button>


       <br></br>
<br></br>
           
           <div  style={{color: 'white', fontSize: '18px',
                  fontWeight: 'bold'}} onClick={() =>  setShowModal4(true)}>Laws </div>
      
<br></br>
      {presidentLaws()}
      <Button id='presidentViewMoreButton' onClick={() =>  setShowModal4(true)}>  <Files size={20}></Files> View More</Button>

       <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal3} onHide={() => setShowModal3(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3> {name}'s State of the Union Addresses</h3></Modal.Header>
          <div id='searchForm'>
        <Form className="text-center">
          <Form.Group className="d-flex justify-content-center align-items-center">
          <Form.Control
  variant="light"
  id="searchInput"
  placeholder="Search..."
  type="text"
  color="white"
  value={searchQueryModal}
  onChange={(e) => setSearchQueryModal(e.target.value)}
/>
            <Button id='ggButton' onClick={() => setIsSearchTriggered(true)} >
              <Search /> 
            </Button>
          </Form.Group>
        </Form>
      </div>
          <div id='fullBillFeed' >
       
            {stateOfTheUnionModal()}

  </div>
        </Modal>

        <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal4} onHide={() => setShowModal4(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3> {name}'s Laws</h3></Modal.Header>
          <div id='searchForm'>
        <Form className="text-center">
          <Form.Group className="d-flex justify-content-center align-items-center">
          <Form.Control
  variant="light"
  id="searchInput"
  placeholder="Search..."
  type="text"
  color="white"
  value={searchQueryModal}
  onChange={(e) => setSearchQueryModal(e.target.value)}
/>
            <Button id='ggButton' onClick={() => setIsSearchTriggered(true)} >
              <Search /> 
            </Button>
          </Form.Group>
        </Form>
      </div>
          <div id='fullBillFeed' >
       
            {presidentLawsModal()}

  </div>
        </Modal>

       <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal2} onHide={() => setShowModal2(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3> {name}'s Presidential Documents</h3></Modal.Header>
          <div id='searchForm'>
        <Form className="text-center">
          <Form.Group className="d-flex justify-content-center align-items-center">
          <Form.Control
  variant="light"
  id="searchInput"
  placeholder="Search..."
  type="text"
  color="white"
  value={searchQueryModal}
  onChange={(e) => setSearchQueryModal(e.target.value)}
/>
            <Button id='ggButton' onClick={() => setIsSearchTriggered(true)} >
              <Search /> 
            </Button>
          </Form.Group>
        </Form>
      </div>
          <div id='fullBillFeed' >
       
            {presidentialDocsModal()}

  </div>
        </Modal>


        <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> {name}'s Biography</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <div dangerouslySetInnerHTML={{ __html: description }} /> 

 </div>

       </Modal>
            <br></br><br></br><br></br><br></br><br></br><br></br><Navbar id='navbarMobileBottom' fixed="bottom">    

          


       
<div id='changeBranchDropdownMobile'>

           <MobileOffCanvas/>
        
            </div>

</Navbar>
            



            <Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header id='billModalHeader' closeButton><h3> {name}'s Executive Orders</h3></Modal.Header>
          <div id='searchForm'>
        <Form className="text-center">
          <Form.Group className="d-flex justify-content-center align-items-center">
          <Form.Control
  variant="light"
  id="searchInput"
  placeholder="Search..."
  type="text"
  color="white"
  value={searchQueryModal}
  onChange={(e) => setSearchQueryModal(e.target.value)}
/>
            <Button id='ggButton' onClick={() => setIsSearchTriggered(true)} >
              <Search /> 
            </Button>
          </Form.Group>
        </Form>
      </div>
          <div id='fullBillFeed' >
       
            {renderDataModal()}

  </div>
        </Modal>



            <Modal id='fullBillModal' show={collectionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" 
centered onHide={setCollectionModalIsOpen}>
                             
                              <Modal.Header id='billModalHeader' closeButton ><h3>{storedTitle}</h3></Modal.Header>

                              <Modal.Body id='descriptionModalBody'>
                                          {storedDescription}
                                          {storedDate}
                              <div id='fullBill'>
                         <Form id='noteModalForm'>
         <Form.Group id='noteModalForm'  className="mb-3" >
           <Form.Control id="noteModalFeedItem" placeholder="Add a note here" 
             type="text"
             as={"textarea"}
             rows={2}
             />
             <br></br>

         </Form.Group>
         </Form>

         <Modal.Footer id='addNoteModalFooter'> 

<Button variant="primary" id='noteSaveToCollectionButton' onClick={() => {saveNote(storedTitle, storedDetailsLink, storedDocLink, 
  storedDate, storedFeedName, storedBranchName, storedTopicName, storedDescription) ;setCollectionModalIsOpen(false) }} >
    Save To Collection
  </Button>
  </Modal.Footer> 

           </div>
           </Modal.Body>
                              </Modal>
         
            </div>
</div>
            </MobileView>
        </>
    )
}

export default PresidentPage;