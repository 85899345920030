import App from "./App";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect, Fragment, lazy, Suspense } from 'react';
import { Navbar, Nav, Container, Button, Modal, Form, FormControl, Card, Col, Row, Accordion, NavDropdown, Table, Alert, Toast, Carousel, ModalBody, Dropdown, Tabs, Tab, Image, ModalFooter, Spinner } from 'react-bootstrap';
import { ArrowsAngleExpand, InfoCircle, FileText, FolderPlus, Files, Folder, HeartFill, PauseCircle, ChevronRight, Pen, ChatLeftQuote, PlayCircle } from 'react-bootstrap-icons'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserView, MobileView } from 'react-device-detect';
import { render } from '@testing-library/react';
import GAOReports from "./GAOReports";
import CongressionalBudgetOffice from "./CongressionalBudgetOffice";
import LegislativeBranchMobile from "./LegislativeBranchMobile";
import { congressSearch, CreateFeedItem, CreateFeedItemGAOReports, CreateFeedAPI, CreateFeedAPIWithPage
} from './Functions'
import DesktopNavbar from "./DesktopNavbar";
import GenerateFeed from "./GenerateFeed";
import GenerateFeedPageCreator from "./GenerateFeedPageCreator";
import RepresenativesDisplay from "./RepresenativesDisplay";
import SenateDisplay from "./SenateDisplay";
import HouseOfRepsDisplay from "./HouseOfRepsDisplay";

var carouselInterval = 30000;
const HeavyDependencyComponent = lazy(() => import('./GenerateFeed'));
const CustomModal = ({ isOpen, onClose, title, content }) => (
  <Modal id='fullBillModal' show={isOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={onClose}>
    <Modal.Header id='billModalHeader' closeButton><h3>{title}</h3></Modal.Header>
    {content}
  </Modal>
);

export const LegislativeBranch = () => {
  const [modalInfo, setModalInfo] = useState({
    isOpen: false,
    title: '',
    content: null
  });

  const openModal = (title, content) => {
    setModalInfo({
      isOpen: true,
      title,
      content
    });
  };

  const closeModal = () => {
    setModalInfo({
      isOpen: false,
      title: '',
      content: null
    });
  };
  render()

  {
    const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false)
    const [showBioModal, setBioModal] = useState(false);
    return (
      <>
        <BrowserView>
          <div id='homePage'>


            <div id='homebg'>
              <DesktopNavbar />


              {/* <div id='sectionHeading'>

                <h3 id='branchHeading'> <Pen></Pen> Legislative

                  <Button size='md' id='navbarAboutButtonDesktop' variant='primary' onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.congress.gov/', "_blank")
                  }} >congress.gov</Button>

                  <Button size='md' id='navbarAboutButtonDesktop' variant='primary' onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.house.gov/', "_blank")
                  }} >house.gov</Button>


                  <Button size='md' id='navbarAboutButtonDesktop' variant='primary' onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.senate.gov/', "_blank")
                  }} >senate.gov</Button>


                  <Button size='md' id='navbarAboutButtonDesktop' variant='primary' onClick={(e) => {
                    e.preventDefault();
                    window.open('https://www.congress.gov/congressional-record', "_blank")
                  }} >Congressional Record</Button>




                  <Form id='congressSearchForm'>

                    <Form.Group className="mb-3 d-flex">

                      <Form.Control id="congressSearch" placeholder="Search congress.gov"
                        type="text" />

                      <Button size='md' variant="primary" id='navbarAboutButtonDesktop' onClick={() => congressSearch()}>
                        Search
                      </Button>
                    </Form.Group>

                  </Form>


                </h3>
              </div> */}

<div style={{ position: 'relative' }}>

<div id='profileImage' style={{ width: '130px', height: '130px', borderRadius: '100%', overflow: 'hidden',  marginLeft: '60px' }}>
<Image id='profileImage-image' src='/legislativeBranch.png' />
</div>


<div style={{ position: 'absolute', top: '0', left: '250px', display: 'flex', flexDirection: 'column' }}>
<div style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>

</div>
<div  style={{ color: 'white', fontSize: '20px' }}> 
<span> <h3  >Legislative Branch</h3></span>
<br></br>
<div style={{ color: 'white', fontSize: '16px', width: '80%' }}>
<span> 
  Established by Article I of the Constitution, the Legislative Branch consists of the House of Representatives and the Senate, which together form the United States Congress.</span>
  The House of Representatives is made up of 435 elected members, divided among the 50 states in proportion to their total population. In addition, there are 6 non-voting members...
<br></br>
<div style={{ color: 'white', fontSize: '18px' }}>

  <span><Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-legislative-branch/", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-legislative-branch/
     </Link></span> 
<br></br>
<Button id='whDocsViewMoreButton' onClick={() =>  setBioModal(true)}>  <Files size={20}></Files> Read More</Button>
</div>
</div>

</div>
</div>


</div>
<br></br>
<br></br><br></br>
<Modal id='fullBillModal'  size="xl" aria-labelledby="contained-modal-title-vcenter" show={showBioModal} onHide={() => setBioModal(false)}>
         <Modal.Header id='billModalHeader' closeButton><h3> The Legislative Branch</h3></Modal.Header>
       
         <div id='fullBillFeed' >
      
         <p>Established by Article I of the Constitution, the Legislative Branch consists of the House of Representatives and the Senate, which together form the United States Congress. The Constitution grants Congress the sole authority to enact legislation and declare war, the right to confirm or reject many Presidential appointments, and substantial investigative powers.</p>

<p>The House of Representatives is made up of 435 elected members, divided among the 50 states in proportion to their total population. In addition, there are 6 non-voting members, representing the District of Columbia, the Commonwealth of Puerto Rico, and four other territories of the United States: American Samoa, Guam, the U.S. Virgin Islands, and the Commonwealth of Northern Mariana Islands. The presiding officer of the chamber is the Speaker of the House, elected by the Representatives. He or she is third in the line of succession to the Presidency.</p>

<p>Members of the House are elected every two years and must be 25 years of age, a U.S. citizen for at least seven years, and a resident of the state (but not necessarily the district) they represent.</p>

<p>The House has several powers assigned exclusively to it, including the power to initiate revenue bills, impeach federal officials, and elect the President in the case of an Electoral College tie.</p>

<p>The Senate is composed of 100 Senators, 2 for each state. Until the ratification of the 17th Amendment in 1913, Senators were chosen by state legislatures, not by popular vote. Since then, they have been elected to six-year terms by the people of each state. Senators’ terms are staggered so that about one-third of the Senate is up for reelection every two years. Senators must be 30 years of age, U.S. citizens for at least nine years, and residents of the state they represent.</p>

<p>The Vice President of the United States serves as President of the Senate and may cast the decisive vote in the event of a tie in the Senate.</p>

<p>The Senate has the sole power to confirm those of the President’s appointments that require consent, and to provide advice and consent to ratify treaties. There are, however, two exceptions to this rule: the House must also approve appointments to the Vice Presidency and any treaty that involves foreign trade. The Senate also tries impeachment cases for federal officials referred to it by the House.</p>

<p>In order to pass legislation and send it to the President for his or her signature, both the House and the Senate must pass the same bill by majority vote. If the President vetoes a bill, they may override his veto by passing the bill again in each chamber with at least two-thirds of each body voting in favor.</p>

<p><strong>The Legislative Process</strong></p>

<p>The first step in the legislative process is the introduction of a bill to Congress. Anyone can write it, but only members of Congress can introduce legislation. Some important bills are traditionally introduced at the request of the President, such as the annual federal budget. During the legislative process, however, the initial bill can undergo drastic changes.</p>

<p>After being introduced, a bill is referred to the appropriate committee for review. There are 17 Senate committees, with 70 subcommittees, and 23 House committees, with 104 subcommittees. The committees are not set in stone, but change in number and form with each new Congress as required for the efficient consideration of legislation. Each committee oversees a specific policy area, and the subcommittees take on more specialized policy areas. For example, the House Committee on Ways and Means includes subcommittees on Social Security and Trade.</p>

<p>A bill is first considered in a subcommittee, where it may be accepted, amended, or rejected entirely. If the members of the subcommittee agree to move a bill forward, it is reported to the full committee, where the process is repeated again. Throughout this stage of the process, the committees and subcommittees call hearings to investigate the merits and flaws of the bill. They invite experts, advocates, and opponents to appear before the committee and provide testimony, and can compel people to appear using subpoena power if necessary.</p>

<p>If the full committee votes to approve the bill, it is reported to the floor of the House or Senate, and the majority party leadership decides when to place the bill on the calendar for consideration. If a bill is particularly pressing, it may be considered right away. Others may wait for months or never be scheduled at all.</p>

<p>When the bill comes up for consideration, the House has a very structured debate process. Each member who wishes to speak only has a few minutes, and the number and kind of amendments are usually limited. In the Senate, debate on most bills is unlimited — Senators may speak to issues other than the bill under consideration during their speeches, and any amendment can be introduced. Senators can use this to filibuster bills under consideration, a procedure by which a Senator delays a vote on a bill — and by extension its passage — by refusing to stand down. A supermajority of 60 Senators can break a filibuster by invoking cloture, or the cession of debate on the bill, and forcing a vote. Once debate is over, the votes of a simple majority pass the bill.</p>

<p>A bill must pass both houses of Congress before it goes to the President for consideration. Though the Constitution requires that the two bills have the exact same wording, this rarely happens in practice. To bring the bills into alignment, a Conference Committee is convened, consisting of members from both chambers. The members of the committee produce a conference report, intended as the final version of the bill. Each chamber then votes again to approve the conference report. Depending on where the bill originated, the final text is then enrolled by either the Clerk of the House or the Secretary of the Senate, and presented to the Speaker of the House and the President of the Senate for their signatures. The bill is then sent to the President.</p>

<p>When receiving a bill from Congress, the President has several options. If the President agrees substantially with the bill, he or she may sign it into law, and the bill is then printed in the Statutes at Large. If the President believes the law to be bad policy, he or she may veto it and send it back to Congress. Congress may override the veto with a two-thirds vote of each chamber, at which point the bill becomes law and is printed.</p>

<p>There are two other options that the President may exercise. If Congress is in session and the President takes no action within 10 days, the bill becomes law. If Congress adjourns before 10 days are up and the President takes no action, then the bill dies and Congress may not vote to override. This is called a pocket veto, and if Congress still wants to pass the legislation, they must begin the entire process anew.</p>

<p><strong>Powers of Congress</strong></p>

<p>Congress, as one of the three coequal branches of government, is ascribed significant powers by the Constitution. All legislative power in the government is vested in Congress, meaning that it is the only part of the government that can make new laws or change existing laws. Executive Branch agencies issue regulations with the full force of law, but these are only under the authority of laws enacted by Congress. The President may veto bills Congress passes, but Congress may also override a veto by a two-thirds vote in both the Senate and the House of Representatives.</p>

<p>Article I of the Constitution enumerates the powers of Congress and the specific areas in which it may legislate. Congress is also empowered to enact laws deemed “necessary and proper” for the execution of the powers given to any part of the government under the Constitution.</p>

<p>Part of Congress’s exercise of legislative authority is the establishment of an annual budget for the government. To this end, Congress levies taxes and tariffs to provide funding for essential government services. If enough money cannot be raised to fund the government, then Congress may also authorize borrowing to make up the difference. Congress can also mandate spending on specific items: legislatively directed spending, commonly known as “earmarks,” specifies funds for a particular project, rather than for a government agency.</p>

<p>Both chambers of Congress have extensive investigative powers, and may compel the production of evidence or testimony toward whatever end they deem necessary. Members of Congress spend much of their time holding hearings and investigations in committee. Refusal to cooperate with a congressional subpoena can result in charges of contempt of Congress, which could result in a prison term.</p>

<p>The Senate maintains several powers to itself: It consents to the ratification of treaties by a two-thirds supermajority vote and confirms the appointments of the President by a majority vote. The consent of the House of Representatives is also necessary for the ratification of trade agreements and the confirmation of the Vice President.</p>

<p>Congress also holds the sole power to declare war.</p>

<p><strong>Government Oversight</strong></p>

<p>Oversight of the executive branch is an important Congressional check on the President’s power and a balance against his or her discretion in implementing laws and making regulations.</p>

<p>One primary way that Congress conducts oversight is through hearings. The House Committee on Oversight and Government Reform and the Senate Committee on Homeland Security and Government Affairs are both devoted to overseeing and reforming government operations, and each committee conducts oversight in its policy area.</p>

<p>Congress also maintains an investigative organization, the Government Accountability Office (GAO). Founded in 1921 as the General Accounting Office, its original mission was to audit the budgets and financial statements sent to Congress by the Secretary of the Treasury and the Director of the Office of Management and Budget. Today, the GAO audits and generates reports on every aspect of the government, ensuring that taxpayer dollars are spent with the effectiveness and efficiency that the American people deserve.</p>

<p>The Executive Branch also polices itself: Sixty-four Inspectors General, each responsible for a different agency, regularly audit and report on the agencies to which they are attached.</p>

<br></br>
<Link style={{color: '#21cff5'}} onClick={(e) => {
     e.preventDefault();
     window.open("https://www.whitehouse.gov/about-the-white-house/our-government/the-legislative-branch/", "_blank");
   }}>
     https://www.whitehouse.gov/about-the-white-house/our-government/the-legislative-branch/
     </Link>
 </div>

       </Modal>

       <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferOne'>
                    <Row>

                      <Col id='legislativeColRight' >
                        <div id='columnRightPadding'>
                          <h4 id='billFeedHeading' onClick={() =>
                            openModal('Bill Updates',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=created_at'
                                name='Bill Updates' branch='Legislative' topic='N/A' buttonName='Bill' />)}><b>Bill Updates</b></h4>


                          <div id='bills'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_bills&order_by=created_at', 'bills',
                            'Bill Updates', 'Legislative',
                            'N/A', 'Bill', GenerateFeed)}

                        </div>
                      </Col>



               
                      <Col id='legislativeColRight' >
                        <div id='columnRightPadding'>

                          <h4 id='billFeedHeading' onClick={() =>
                            openModal('Bills Enrolled',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=united_states_of_america&table=congressional_bills_enrolled&order_by=created_at'
                                name='Bills Enrolled' branch='Legislative' topic='N/A' buttonName='Bill' />)}>
                            <b>Bills Enrolled (Sent to President)</b></h4>
                          <div id='billsEnrolled'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_bills_enrolled&order_by=created_at',
                            'billsEnrolled',
                            'Bills Enrolled', 'Legislative',
                            'N/A', 'Bill', GenerateFeed)}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferOne'>
                    <Row>
                      <Col id='legislativeColLeft' >
                        <div id='columnLefttPadding'>
                          <h4 id='feedHeading' onClick={() =>
                            openModal('Laws',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=united_states_of_america&table=public_and_private_laws&order_by=created_at'
                                name='Laws' branch='Legislative' topic='N/A' buttonName='Law' />)}><b>Laws</b></h4>

                          <div id='newLaws'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=public_and_private_laws&order_by=created_at', 'newLaws',
                            'Laws', 'Legislative',
                            'N/A', 'Law', GenerateFeed)}

                        </div>
                      </Col>

                      <Col id='legislativeColLeft'>
                        <div id='columnLefttPadding'>

                          <h4 id='billFeedHeading' onClick={() =>
                            openModal('Congressional Hearings',
                              <HeavyDependencyComponent
                                url='&skip=0&schema=united_states_of_america&table=congressional_hearings&order_by=created_at'
                                name='Congressional Hearings' branch='Legislative' topic='N/A' buttonName='Hearing' />)}><b>Congressional Hearings</b></h4>


                          <div id='congressionalHearings'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_hearings&order_by=created_at', 'congressionalHearings',
                            'Congressional Hearings', 'Legislative',
                            'N/A', 'Hearing', GenerateFeed)}
                        </div>
                      </Col>
                    </Row>


                  </Container>
                </Container>
              </div>

              <div id='branchPage'>
                <Container class='bgcolor' >

                  <Container id='carouselBufferOne'>
                    <Row>
                      <Col id='legislativeColRightt'>
                        <div id='columnRightPadding'>
                          <h4 id='feedHeadingBillsEnrolled' onClick={() => openModal('Congressional Reports',
                            <HeavyDependencyComponent
                              url='&skip=0&schema=united_states_of_america&table=congressional_reports&order_by=created_at'
                              name='Congressional Reports' branch='Legislative' topic='N/A' buttonName='Hearing' />)}><b>Congressional Reports</b></h4>
                          <div id='reports'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table_name=congressional_reports&order_by=created_at', 'reports',
                            'Congressional Reports', 'Legislative',
                            'N/A', 'Report', GenerateFeed)}
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnLefttPadding'>

                          <h4 id='feedHeadingBillsEnrolled' ><b>Government Accountability Office Reports</b></h4>
                          <div id='gaoReports'></div>

                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=government_accountability_office_reports&order_by=created_at', 'gaoReports', 'Government Accountability Office Reports', 'Legislative', 'N/A', GAOReports)}
                        
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>

              </div>
              <div id='branchPage'>
                <Container class='bgcolor' >
                  <Container id='carouselBufferOne'>
                    <Row>
                      <Col id='legislativeColLeft'>
                        <div id='columnRightPadding'>
                          <h4 id='feedHeadingBillsEnrolled' ><b>Congressional Budget Office</b></h4>
                          <div id='congressionalBudgetOffice'></div>
                          {CreateFeedAPI('&skip=0&schema=united_states_of_america&table=congressional_budget_office&order_by=created_at', 'congressionalBudgetOffice', 'Congressional Budget Office', 'Legislative', 'Economy', CongressionalBudgetOffice)}
                        </div>
                      </Col>

                      <Col id='legislativeColRight'>
                        <div id='columnLefttPadding'>
                        </div>
                      </Col>
                    </Row>
                    {/*Global Modal */}
                    <Suspense fallback={<Spinner animation="grow" variant="light" />}>
                      <CustomModal isOpen={modalInfo.isOpen} onClose={closeModal} title={modalInfo.title} content={modalInfo.content} />
                    </Suspense>


                  </Container>
                </Container>

              </div>
              
                   <Row id='legislativeGroups'  >
                   <Col id='topicColumn' >
               


         

                </Col>
                    
                <Col id='topicColumn' >
                  <Row id="homePageBranchRow" >
                    <h3 id='branchHeading' onClick={() => window.open('/us/house', "_self")}> <b id='branchHeadingBold'>House</b><ChevronRight id='clickChevron'></ChevronRight></h3>

                  </Row>


                  <Carousel id='homeCarousel' controls={false}  >

                  <Carousel.Item interval={carouselInterval} >
                  <div id='repCarouselDisplayDesktop'>
             
             <Col>
             <div id='columnLefttPadding'>
             <h5 id='repCarouselHeading' >Representatives</h5>
             <HouseOfRepsDisplay/>
            
           </div>
             </Col>
             
             </div>
                    </Carousel.Item>
                  <Carousel.Item interval={carouselInterval} >
                    <div id='repCarouselDisplayDesktop'>
             
             <Col>
             <div id='viewFullPageButton'>
             <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/house' >View House Committees</Button>
             </div>
             </Col>
             </div>
                    </Carousel.Item>


                  </Carousel>

                </Col>
                <Col id='topicColumn'>
                  <Row id="homePageBranchRow">



                    <h3 id='branchHeading' onClick={() => window.open('/us/senate', "_self")}> <b id='branchHeadingBold'>Senate</b><ChevronRight id='clickChevron'></ChevronRight></h3>
                  </Row>

                  <Carousel id='homeCarousel' controls={false}  >

                  <Carousel.Item interval={carouselInterval} >
                  <div id='repCarouselDisplayDesktop'>
             
             <Col>
             <div id='columnLefttPadding'>
             <h5 id='repCarouselHeading' >Senators</h5>
             <SenateDisplay/>
            
           </div>
             </Col>
             
             </div>
                    </Carousel.Item>
                    <Carousel.Item interval={carouselInterval} >
                    <div id='repCarouselDisplayDesktop'>
             
             <Col>
             <div id='viewFullPageButton'>
             <Button id='viewFullLegislativePageButtonMobile' size='lg' href='/us/senate' >View Senate Committees</Button>
             </div>
             </Col>
             </div>
                    </Carousel.Item>
                  </Carousel>
                </Col>
                <Col id='topicColumn'>
                  <Row id="homePageBranchRow">                            
                
<Carousel id='homeCarousel' controls={false}  >


</Carousel>
                  
                  </Row>

                
                </Col>

              </Row>


<br></br>

              <footer id='homeFooter'>
                <Navbar>
                  <Row id='footerNav'>
                    <Nav variant='pills'>

                      <Nav.Link id='footerContent'>Gov Glance Foundation Inc.
                      </Nav.Link>
                      <Nav.Item>
                        <Nav.Link id='footerContent' href='mailto:contact@govglance.org'>Contact</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link id='footerContent' onClick={(e) => {
                          e.preventDefault();
                          window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                        }}> <HeartFill id='donateIcon' />    Donate</Nav.Link>
                      </Nav.Item>
                      <Nav.Link onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}} id='footerContent'>Privacy</Nav.Link>


                    </Nav>
                  </Row>
                </Navbar>
              </footer>





            </div>
          </div>
        </BrowserView>

        <MobileView>
          <LegislativeBranchMobile />

        </MobileView>
      </>
    )
  }
}










export default LegislativeBranch;