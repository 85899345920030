import React, { useState } from 'react';
import { Navbar, Nav, Button, Modal, Col, Row, ModalBody, Dropdown, Image } from 'react-bootstrap';
import { GeoAlt, HeartFill, Files, Folder, InfoCircle, AppIndicator } from 'react-bootstrap-icons';
import { BrowserView } from 'react-device-detect';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import StatesModal from './StatesModal';
import NavbarSearchButton from './NavbarSearchButton'



const DesktopNavbar = () => {
  const [aboutModalIsOpen, setAboutModalIsOpen] = useState(false);
  const [declarationModalIsOpen, setDeclarationModalIsOpen] = useState(false);
  const [constitutionModalIsOpen, setConstitutionModalIsOpen] = useState(false);
  const [billOfRightsModalIsOpen, setBillOfRightsModalIsOpen] = useState(false);
  const [statesModalIsOpen, setStatesModalIsOpen] = useState(false);
  const [selectedState, setSelectedState] = useState('');

  const states = [
    'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado',
    'Connecticut', 'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho',
    'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana',
    'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota',
    'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
    'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
    'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon',
    'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota',
    'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia',
    'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
  ];
  
  const formatStateFlagName = (name) => {
    const words = name.split(' ');
    return (
      words[0].toLowerCase() + // Lowercase the first word
      words
        .slice(1)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize subsequent words
        .join('') +
      'Flag'
    );
  };
  
  const formatStateNameForURL = (name) => 
    name.toLowerCase().replace(/\s+/g, '_');

    const handleStateSelect = (newState) => {
      setSelectedState(newState);
      window.open(`/us/${formatStateNameForURL(newState)}`, '_self');
    };
  
  
  return (
    <>
    
      <div className="App">
        <BrowserView>
          <div id='homePage'>
            
            <Navbar id='navbar'>
            
              <Col>
                <Row id='realNavbarTop'>
                  <Nav>
                    <h3 id='branchesHeadingUS'><b id='sloganHeading'> </b></h3>
                    <Nav.Link id='navbarAboutDesktop' ><Button className='clickable' id='navbarAboutButtonDesktop' onClick={(e) => {
                      e.preventDefault();
                      window.open('/', "_self")
                    }} > <Image id='govGlanceLogo' width='22px' src='/favicon.ico'></Image> Home
                        </Button></Nav.Link>

                    <Nav.Link id='navbarAboutDesktop'><Button className='clickable' id='navbarAboutButtonDesktop' onClick={(e) => {
                   e.preventDefault();
                   window.open('/about', "_self")}}> <InfoCircle/> About</Button></Nav.Link>
                    <Nav.Link id='navbarAboutDesktop'><Button className='clickable' id='navbarAboutButtonDesktop' onClick={(e) => {
                      e.preventDefault();
                      window.open('/collection', "_self")
                    }}><Folder></Folder> Collection</Button></Nav.Link>
                     <Nav.Link id='navbarAboutDesktop' >
                          <Dropdown drop="down-centered">

                            <Dropdown.Toggle id='navbarAboutButtonDesktop'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
</svg> Apps
                            </Dropdown.Toggle>
<Dropdown.Menu id="docsDropdown" drop="down-centered">
                              <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('https://apps.apple.com/us/app/gov-glance-news/id6448072618', "_blank")
                                }}
                              >
                                <svg id='appIconsDesktop' xmlns="http://www.w3.org/2000/svg" class="ionicon" height={24} fill='white' viewBox="0 0 512 512"><path d="M256 32C132.26 32 32 132.26 32 256s100.26 224 224 224 224-100.26 224-224S379.74 32 256 32zm-85 321.89a15.48 15.48 0 01-13.46 7.65 14.91 14.91 0 01-7.86-2.16 15.48 15.48 0 01-5.6-21.21l15.29-25.42a8.73 8.73 0 017.54-4.3h2.26c11.09 0 18.85 6.67 21.11 13.13zm129.45-50l-100.13.11h-66.55a15.46 15.46 0 01-15.51-16.15c.32-8.4 7.65-14.76 16-14.76h48.24l57.19-97.35-18.52-31.55C217 137 218.85 127.52 226 123a15.57 15.57 0 0121.87 5.17l9.9 16.91h.11l9.91-16.91A15.58 15.58 0 01289.6 123c7.11 4.52 8.94 14 4.74 21.22l-18.52 31.55-18 30.69-39.09 66.66v.11h57.61c7.22 0 16.27 3.88 19.93 10.12l.32.65c3.23 5.49 5.06 9.26 5.06 14.75a13.82 13.82 0 01-1.17 5.17zm77.75.11h-27.11v.11l19.82 33.71a15.8 15.8 0 01-5.17 21.53 15.53 15.53 0 01-8.08 2.27A15.71 15.71 0 01344.2 354l-29.29-49.86-18.2-31L273.23 233a38.35 38.35 0 01-.65-38c4.64-8.19 8.19-10.34 8.19-10.34L333 273h44.91c8.4 0 15.61 6.46 16 14.75A15.65 15.65 0 01378.23 304z" /></svg>
                      iOS
                              </Dropdown.Item>
                              <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('https://play.google.com/store/apps/details?id=com.govglance1&hl=en_US&gl=US', "_blank")
                                }}
                              >
                                <svg id='appIconsDesktop' xmlns="http://www.w3.org/2000/svg" class="ionicon" fill='white' height={24} viewBox="0 0 512 512"><path d="M48 59.49v393a4.33 4.33 0 007.37 3.07L260 256 55.37 56.42A4.33 4.33 0 0048 59.49zM345.8 174L89.22 32.64l-.16-.09c-4.42-2.4-8.62 3.58-5 7.06l201.13 192.32zM84.08 472.39c-3.64 3.48.56 9.46 5 7.06l.16-.09L345.8 338l-60.61-57.95zM449.38 231l-71.65-39.46L310.36 256l67.37 64.43L449.38 281c19.49-10.77 19.49-39.23 0-50z" />
                      </svg> Android
                              </Dropdown.Item>

                         
                            </Dropdown.Menu>
                            </Dropdown>
                        </Nav.Link>
                  
                    <Nav.Link id='navbarAboutDesktop'><Button className='clickable' id='navbarAboutButtonDesktop' onClick={(e) => {
                      e.preventDefault();
                      window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                    }}><HeartFill id='donateIcon' />    Donate</Button></Nav.Link>
                 
                    <NavbarSearchButton />  
                  
                  </Nav>
                </Row>
                <Row id='testRow' >
                  <Col xs={12}>

                    <Navbar id='bottomNavbar' >
                      <Nav variant='pills' className="button-container-nav">
                        <Nav.Link id='navbarAboutDesktop' >
                          <Dropdown drop="down-centered">

                            <Dropdown.Toggle id='navbarAboutButtonDesktop'>
                              🇺🇸
                            </Dropdown.Toggle>
                            <Dropdown.Menu id="docsDropdown" drop="down-centered">
                            <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('/', "_self");
                                }}
                              >
                                🌐 All Countries
                              </Dropdown.Item>
                              <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('/canada', "_self");
                                }}
                              >
                                🇨🇦 Canada
                              </Dropdown.Item>
                              <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('/mexico', "_self");
                                }}
                              >
                                🇲🇽 Mexico
                              </Dropdown.Item>

                              <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('/uk', "_self");
                                }}
                              >
                                🇬🇧 United Kingdom
                              </Dropdown.Item>

                              <Dropdown.Item
                                id="docsDropdownItem"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open('/us', "_self");
                                }}
                              >
                                🇺🇸 United States
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Nav.Link>
                        <Nav.Link id='navbarAboutDesktop' >
                        <Dropdown drop="down-centered" className="ml-3">
                    <Dropdown.Toggle id="navbarAboutButtonDesktop" aria-label="Select State">
                      States
                    </Dropdown.Toggle>

                    <Dropdown.Menu id="docsDropdown">
                      {states.map((stateName) => (
                        <Dropdown.Item
                          key={stateName}
                          id="docsDropdownItem"
                          onClick={() => handleStateSelect(stateName)}
                        >
                          <Image
                            src={`/${formatStateFlagName(stateName)}.png`}
                            alt={`${stateName} flag`}
                            width="20"
                            height="12"
                            className="mr-2"
                          />
                          {stateName}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                    </Nav.Link>
                        <Nav.Link id='navbarAboutDesktop' >
                          <Dropdown >
                            <Dropdown.Toggle id='navbarAboutButtonDesktop' >
                              <Files />
                            </Dropdown.Toggle>
                            <Dropdown.Menu id='docsDropdown'>
                              <Dropdown.Item id='docsDropdownItem' onClick={(e) => {
                                e.preventDefault();
                                window.open('https://www.archives.gov/founding-docs/declaration-transcript', "_blank")}}>Declaration of Independence</Dropdown.Item>
                              <Dropdown.Item id='docsDropdownItem' onClick={(e) => {
                                e.preventDefault();
                                window.open('https://constitution.congress.gov/constitution/', "_blank")
                              }}>Constitution</Dropdown.Item>
                              <Dropdown.Item id='docsDropdownItem' onClick={(e) => {
                                e.preventDefault();
                                window.open('https://www.archives.gov/founding-docs/bill-of-rights-transcript', "_blank")}}>Bill of Rights</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Nav.Link>
                        <div id='branchPageButtonPadding'> <h3 id='branchesHeading'><b id='branchHeadingBold'>Branches</b></h3> </div>
                        <div id='branchPageButtonPadding'>
                          <Button className='clickable' id='navbarAboutButtonDesktop' href='/us/legislative' >Legislative</Button></div>
                        <div id='branchPageButtonPadding'> <Button className='clickable' id='navbarAboutButtonDesktop' href='/us/executive' >Executive</Button></div>
                        <div id='branchPageButtonPadding'><Button className='clickable' id='navbarAboutButtonDesktop' href='/us/judicial' >Judicial</Button></div>
                        <div id='branchPageButtonPadding'> <h3 id='branchesHeading'><b id='branchHeadingBold'>Topics</b></h3> </div>
                        <div id='branchPageButtonPadding'>
                          <Button className='clickable' id='navbarAboutButtonDesktop' href='/us/defense'>Defense</Button></div>
                        <div id='branchPageButtonPadding'>
                          <Button className='clickable' id='navbarAboutButtonDesktop' href='/us/economy'>Economy</Button></div>
                          <div id='branchPageButtonPadding'>
                          <Button className='clickable' id='navbarAboutButtonDesktop' href='/us/elections'>Elections</Button></div>
                        <div id='branchPageButtonPadding'>
                          <Button className='clickable' id='navbarAboutButtonDesktop' href='/us/environment' >Environment</Button></div>
                        <div id='branchPageButtonPadding'>
                          <Button className='clickable' id='navbarAboutButtonDesktop' href='/us/foreignaffairs' >Foreign Affairs</Button></div>
                        <div id='branchPageButtonPadding'>
                          <Button className='clickable' id='navbarAboutButtonDesktop' href='/us/health' >Health</Button></div>
                          <div id='branchPageButtonPadding'>
                          <Button className='clickable' id='navbarAboutButtonDesktop' href='/us/immigration' >Immigration</Button></div>
                        <div id='branchPageButtonPadding'>
                          <Button className='clickable' id='navbarAboutButtonDesktop' href='/us/infrastructure'>Infrastructure</Button></div>
                        <div id='branchPageButtonPadding'>
                          <Button className='clickable' id='navbarAboutButtonDesktop' href='/us/justice'>Justice</Button></div>
                        <div id='branchPageButtonPadding'>
                          <Button className='clickable' id='navbarAboutButtonDesktop' href='/us/science&tech' >Science & Tech</Button></div>
                          <div id='branchPageButtonPadding'>
                          <Button className='clickable' id='navbarAboutButtonDesktop' href='/us/regulations' >Regulations</Button></div>
                        {/* <div id='branchPageButtonPadding'>
                          <Button className='clickable' id='navbarAboutButtonDesktop' onClick={() => setStatesModalIsOpen(true)}  >States</Button></div>

                        <Modal id='fullBillModal' show={statesModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setStatesModalIsOpen(false)}>
                          <Modal.Header id='billModalHeader' closeButton><h3 id='branchHeading' > <GeoAlt></GeoAlt> <b id='branchHeadingBold'> Select a State</b></h3>
                          </Modal.Header>

                          <StatesModal />

                        </Modal> */}
                      </Nav>
                    </Navbar>
                  </Col>
                </Row>
              </Col>
            </Navbar>
         
            <Modal id='aboutModal' show={declarationModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setDeclarationModalIsOpen(false)}>
           

              <Modal.Header id='aboutModalHeader' closeButton ><h3>Declaration of Independence</h3></Modal.Header>


              <iframe  src='https://gov-glance-congress-dailydigest.glitch.me/Declaration%20of%20Independence%20.html' color='white' height='800px' width='1138px' frameBorder='0'></iframe>

              <Modal.Footer><a href='https://www.archives.gov/founding-docs/declaration-transcript'>https://www.archives.gov/founding-docs/declaration-transcript</a></Modal.Footer>

            </Modal>


            <Modal id='aboutModal' show={constitutionModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setConstitutionModalIsOpen(false)}>
              <div id='removedAlert'></div>
              <Modal.Header id='aboutModalHeader' closeButton><h3>Constitution</h3></Modal.Header>

              <iframe src='https://evening-plains-21303.herokuapp.com/https://constitution.congress.gov/constitution/' height='800px' width='1138px' frameBorder='0'></iframe>
              <Modal.Footer><a href='https://www.archives.gov/founding-docs/constitution-transcript'>https://www.archives.gov/founding-docs/constitution-transcript</a>
                <a href='https://constitution.congress.gov/constitution/'>https://constitution.congress.gov/constitution/</a>
              </Modal.Footer>
            </Modal>

            <Modal id='aboutModal' show={billOfRightsModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setBillOfRightsModalIsOpen(false)}>
              <div id='removedAlert'></div>
              <Modal.Header id='aboutModalHeader' closeButton><h3>Bill of Rights</h3></Modal.Header>

              <iframe src='https://gov-glance-congress-dailydigest.glitch.me/The%20U%20S%20Bill%20of%20Rights.html' height='800px' width='1138px' frameBorder='0'></iframe>

              <Modal.Footer><a href='https://www.archives.gov/founding-docs/bill-of-rights-transcript'>https://www.archives.gov/founding-docs/bill-of-rights-transcript</a></Modal.Footer>

            </Modal>

            <Modal id='aboutModal' show={aboutModalIsOpen} size="xl" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setAboutModalIsOpen(false)}>
              <div id='removedAlert'></div>
              <Modal.Header id='aboutModalHeader' closeButton><h3>About</h3></Modal.Header>
              <ModalBody id='aboutInfo'><p id='aboutParagraph'>Gov Glance is a civic engagement tool designed to help citizens
                  get official government news in an easy-to-navigate centralized location.
              There will never be any opinion or political analysis(That's your job), only information from official government
              sources.
              </p>

                <p id='aboutParagraph'>
                  Gov Glance Foundation Inc. is a non-profit dedicated to creating free educational tools and resources. <a id='aboutLink' href='https://govglance.foundation/'>https://govglance.foundation/</a>
                </p>

                <Button className='clickable' id='navbarAboutButtonDesktopMobile' onClick={(e) => {
                  e.preventDefault();
                  window.open('https://donate.stripe.com/28o3fXbdvbQO2RO288', "_blank")
                }}><HeartFill id='donateIcon' />    Donate</Button>
                <br></br> <br></br>

                <h3>Privacy</h3>

                <br></br>

                <p id='aboutParagraph'>Gov Glance does not collect any personal user information.
              Features like collection save news items directly to your browser's local storage for your convenience
              and is not shared with Gov Glance servers. We do not collect, store, or send personal information to Google LLC,
              but we do use some Google services to deliver our app to you. For any questions into Google’s privacy practices
              here is a link to their privacy policy: <a href='https://policies.google.com/privacy?hl=en-US#intro'>https://policies.google.com/privacy?hl=en-US#intro</a>

                  <br></br><br></br>

                  For site analytics we use plausible.io an open source privacy friendly analytics provider. <a href="https://plausible.io/about" target="_blank">https://plausible.io/about</a>

                </p>
                <br></br>


                <h3>Contact</h3>

                <p id='aboutParagraph'>
                  Contact us via email: <a href='mailto:contact@govglance.org'>contact@govglance.org</a>

                </p>

                <br></br>



              </ModalBody>
            </Modal>
          </div>
        </BrowserView>
      </div>
    </>
  );
}

export default DesktopNavbar;
